import { createSlice } from '@reduxjs/toolkit'

export const allInventorySlice = createSlice({
  name: 'allInventory',
  initialState: {
    value: {},
  },
  reducers: {
    updateAllInventory: (state, action) => {
      if (state.value.assets === undefined) {
        state.value.assets = [];
        state.value.descriptions = [];
      }
      for (let i = 0; i < action.payload.assets.length; ++i) {
        if (state.value.assets.findIndex(ele => ele.assetid === action.payload.assets[i].assetid) === -1) {
          state.value.assets.push(action.payload.assets[i]);
          state.value.descriptions.push(action.payload.descriptions[i]);
        }
      }
    },
  }
})

export const { updateAllInventory } = allInventorySlice.actions

export const selectAllInventory = (state) => state.allInventory.value

export default allInventorySlice.reducer