import { configureStore, combineReducers } from '@reduxjs/toolkit'
import loginReducer from '../features/login/login'
import displaySkinReducer from '../features/displaySkin/displaySkin'
import userReducer from '../features/user/user'
import searchKeyReducer from '../features/searchKey/searchKey'
import typeReducer from '../features/type/type'
import inventoryReducer from '../features/inventory/inventory'
import allInventoryReducer from '../features/allInventory/allInventory'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  login: loginReducer,
  displaySkin: displaySkinReducer,
  user: userReducer,
  searchKey: searchKeyReducer,
  type: typeReducer,
  inventory: inventoryReducer,
  allInventory: allInventoryReducer,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export const persistor = persistStore(store);
