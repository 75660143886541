export function extractImageLinks(inputString) {
  if (inputString === null || inputString.length === 0 || !inputString.includes('sticker')) {
    return [];
  }

  const imgTagRegex = /<img [^>]*src=["']([^"']+)["'][^>]*>/g;
  const matches = inputString.match(imgTagRegex);

  if (!matches) {
    return [];
  }

  const imageLinks = matches.map((match) => {
    const srcMatch = /src=["']([^"']+)["']/.exec(match);
    if (srcMatch) {
      return srcMatch[1];
    }
    return null;
  }).filter((link) => link !== null);

  return imageLinks;
}

export function extractImageName(inputString) {
  if (inputString === null || inputString.length === 0 || !inputString.includes('sticker')) {
    return [];
  }
  const slice = inputString.slice(inputString.indexOf('貼紙: ') + 4, inputString.indexOf('</center>'));
  const names = slice.split(', ');
  return names;
}