export const getCookie = (name) => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [cookieName, cookieVal] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieVal);
    }
  }
  return null;
};
