import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: {},
  },
  reducers: {
    updateUser: (state, action) => {
      state.value = action.payload;
    }
  }
})

export const { updateUser } = userSlice.actions

export const selectUser = (state) => state.user.value

export default userSlice.reducer