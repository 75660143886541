import Home from './page/Home/Home';
import Withdraw from './page/Withdraw/Withdraw';
import WithdrawRecord from './page/WithdrawRecord/WithdrawRecord';
import Setting from './page/Setting/Setting';
import MySkinStorage from './page/MySkinStorage/MySkinStorage';
import SkinListPage from './page/SkinList/SkinListPage';
import LoginCB from './component/LoginCB';
import MySale from './page/MySale/MySale';
import PurchaseTutorial from './page/PurchaseTutorial/PurchaseTutorial';
import ContactUs from './page/ContactUs/ContactUs';
import FAQ from './page/FAQ/FAQ';
import AboutUs from './page/AboutUs/AboutUs';
import ShoppingCart from './page/ShoppingCart/ShoppingCart';
import Orders from './page/Orders/Orders';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateLogin } from './features/login/login';
import { updateUser } from './features/user/user';
import { getCookie } from './util/getCookie';
import Admin from './page/Admin/Admin';
import TopNavbar from './component/TopNavbar';
import Footer from './component/Footer';
import NotFound from './page/NotFound/NotFound';
import { api } from './config/api';

function App() {
  const dispatch = useDispatch();

  /**
   * Verify the login status and get the user information.
   */
  const verifyLogin = async () => {
    try {
      const login = await fetch(api.login.verify, {
        headers: {
          Authorization: getCookie('session_key'),
        },
      }).then(res => res.json());

      dispatch(updateLogin(true));
      dispatch(updateUser({ ...login.user }));
    } catch (error) {
      // No valid session key.
      dispatch(updateLogin(false));
      dispatch(updateUser({}));
    }
  }

  useEffect(() => {
    verifyLogin();
  }, [])

  return (
    <Router>
      <div className="App">
        <TopNavbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/withdraw-record' element={<WithdrawRecord />} />
          <Route path='/withdraw' element={<Withdraw />} />
          <Route path='/setting' element={<Setting />} />
          <Route path='/my-skin' element={<MySkinStorage />} />
          <Route path='/skin-list' element={<SkinListPage />} />
          <Route path='/login-cb' element={<LoginCB />} />
          <Route path='/my-sale' element={<MySale />} />
          <Route path='/purchase-tutorial' element={<PurchaseTutorial />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/shopping-cart' element={<ShoppingCart />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/admin' element={<Admin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
