import { useSelector } from "react-redux";
import { selectDisplaySkin } from "../../features/displaySkin/displaySkin";
import Cover from "../../component/Cover";
import MainNavbar from "../../component/MainNavbar";
import SkinList from "./component/SkinList";

const SkinListPage = () => {
  const skin = useSelector(selectDisplaySkin);

  return (
    <div className="basic-page">
      <MainNavbar />
      <Cover item={skin} />
      <SkinList />
    </div>
  );
};

export default SkinListPage;
