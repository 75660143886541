import { Card, Row, Col, Stack } from "react-bootstrap";

const Footer = () => {
  /**
   * Get the current year.
   */
  const year = new Date().getFullYear();

  return (
    <Card
      className='d-flex justify-content-center'
      style={{
        backgroundColor: 'var(--light-lavender)',
        width: '100%',
        height: '300px',
        border: 'none',
        borderRadius: '0',
        overflow: 'hidden'
      }}
    >
      <Row className="text-center d-flex align-items-center">
        <Col xs={5} className="text-start d-flex flex-column" style={{ paddingLeft: '20%' }}>
          <h2 style={{ fontSize: '40px', color: '#ccc' }}>twskins.com</h2>
          <Stack direction="horizontal" gap={3} className='mt-3'>
            <a href='https://fb.me/TWSKINS' target="_blank" rel="noopener noreferrer">
              <img src='facebook.png' style={{ height: '50px' }} alt='facebook' />
            </a>
            <a href='mailto:service@twskins.com' target="_blank" rel="noopener noreferrer">
              <img src='email.png' style={{ height: '50px' }} alt='email' />
            </a>
          </Stack>
          <p className="mt-5" style={{ color: '#ccc' }}>Copyright © {year} TWSKINS</p>
        </Col>
        <Col xs={1}>
          <div style={{ width: '2px', height: '240px', backgroundColor: '#aaa' }}></div>
        </Col>
        <Col xs={3} className="text-start d-flex flex-row" style={{ height: '220px' }}>
          <Stack className="text-start" gap={3}>
            <p style={{ fontSize: '20px', marginBottom: '0px', color: '#ccc' }}>服務</p>
            <a href='/' style={{ color: '#ccc' }}>瀏覽實際庫存</a>
            <a href='/purchase-tutorial' style={{ color: '#ccc' }}>購買教學</a>
          </Stack>
          <Stack className="text-start" gap={3}>
            <p style={{ fontSize: '20px', marginBottom: '0px', color: '#ccc' }}>關於</p>
            <a href='/contact-us' style={{ color: '#ccc' }}>聯絡我們</a>
            <a href='/faq' style={{ color: '#ccc' }}>常見問題</a>
            <a href='/about-us' style={{ color: '#ccc' }}>關於我們</a>
          </Stack>
        </Col>
      </Row>
    </Card>
  )
}

export default Footer;