import { createSlice } from '@reduxjs/toolkit'

export const displaySkinSlice = createSlice({
  name: 'displaySkin',
  initialState: {
    value: {}
  },
  reducers: {
    update: (state, action) => {
      state.value = action.payload;
    }
  }
})

export const { update } = displaySkinSlice.actions

export const selectDisplaySkin = (state) => state.displaySkin.value

export default displaySkinSlice.reducer