import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import moment from "moment-timezone";
import { imagePrefix } from "../../../util/imagePrefix";
import { FiExternalLink } from "react-icons/fi";
import { IoArrowBackSharp } from "react-icons/io5";
import { BankCodeToNameMap } from "../../../util/bankList";
import Loading from "../../../component/Loading";
import { getCookie } from "../../../util/getCookie";
import FloatVisualizer from "../../../component/FloatVisualizer";
import { api } from "../../../config/api";
import showDialog from "../../../component/Dialog/showDialog";

/**
 * 付款方式代碼對照表
 */
const paymentMethodCodeMap = {
  0: "便利商店",
  1: "ATM",
  2: "信用卡",
};

/**
 * 付款狀態代碼對照表
 */
const paymentStatusCodeMap = {
  0: "未付款",
  1: "已付款",
  2: "已取消",
};

const OrderDetails = () => {
  // 已完成的訂單資料
  const [finishedOrders, setFinishedOrders] = useState([]);

  // 未完成的訂單資料
  const [unfinishedOrders, setUnfinishedOrders] = useState([]);

  // 顯示在彈窗中的詳細訂單資料
  const [detailOrders, setDetailOrders] = useState({});

  // 是否顯示訂單詳細資料頁面
  const [showDetailOrders, setShowDetailOrders] = useState(false);

  // 載入中
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, []);

  /**
   * 取得訂單資料
   */
  const fetchOrders = async () => {
    setLoading(true);

    const orders = await fetch(api.purchase.orders, {
      headers: {
        Authorization: getCookie("session_key"),
      },
    }).then((res) => res.json());

    // 依照 id 由大到小排序
    orders.sort((a, b) => b.id - a.id);

    // 取得已完成的訂單
    const finishedOrders = orders.filter(
      (order) =>
        order.payment_status_code === 1 || order.payment_status_code === 2
    );

    const groupedFinishedOrders = [];

    for (const order of finishedOrders) {
      // Find whether the order's self_trade_no is already in the groupedFinishedOrders
      const index = groupedFinishedOrders.findIndex(
        (groupedOrder) => groupedOrder.self_trade_no === order.self_trade_no
      );

      if (index === -1) {
        groupedFinishedOrders.push({
          self_trade_no: order.self_trade_no,
          price: order.price,
          payment_method_code: order.payment_method_code,
          readableCreatedAt: moment(order.createdAt).format("YYYY-MM-DD HH:mm"),
          readableExpiredAt: moment(order.expiredAt).format("YYYY-MM-DD HH:mm"),
          readablePaidAt: order.paidAt
            ? moment(order.paidAt).format("YYYY-MM-DD HH:mm")
            : "--",
          readableLastModifiedAt: moment(order.last_modified).format(
            "YYYY-MM-DD HH:mm"
          ),
          payment_status_code: order.payment_status_code,
          id: order.id,
          contents: [order],
        });
      } else {
        groupedFinishedOrders[index].contents.push(order);
        groupedFinishedOrders[index].price += order.price;
      }
    }

    // 取得未完成的訂單
    const unfinishedOrders = orders.filter(
      (order) => order.payment_status_code === 0
    );

    const groupedUnfinishedOrders = [];

    for (const order of unfinishedOrders) {
      // Find whether the order's self_trade_no is already in the groupedUnfinishedOrders
      const index = groupedUnfinishedOrders.findIndex(
        (groupedOrder) => groupedOrder.self_trade_no === order.self_trade_no
      );

      if (index === -1) {
        groupedUnfinishedOrders.push({
          self_trade_no: order.self_trade_no,
          price: order.price,
          payment_method_code: order.payment_method_code,
          readableCreatedAt: moment(order.createdAt).format("YYYY-MM-DD HH:mm"),
          readableExpiredAt: order.expiredAt,
          payment_status_code: order.payment_status_code,
          paymentNo: order.paymentNo,
          bankCode: order.bankCode,
          vAccount: order.vAccount,
          id: order.id,
          contents: [order],
        });
      } else {
        groupedUnfinishedOrders[index].contents.push(order);
        groupedUnfinishedOrders[index].price += order.price;
      }
    }

    setFinishedOrders(groupedFinishedOrders);
    setUnfinishedOrders(groupedUnfinishedOrders);
    setLoading(false);
  };

  /**
   * 取消訂單
   */
  const handleCancelOrder = async (order) => {
    if (window.confirm("確定要取消訂單嗎？")) {
      setLoading(true);

      const self_trade_no = order.self_trade_no;
      const assetids = order.contents.map((item) => item.assetid);

      const successfullyCanceled = await fetch(api.purchase.orders, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: getCookie("session_key"),
        },
        body: JSON.stringify({
          self_trade_no,
          assetids,
        }),
      }).then((res) => res.json());

      if (successfullyCanceled) {
        setLoading(false);
        await showDialog("訂單已取消");
      } else {
        setLoading(false);
        await showDialog("訂單取消失敗，可能因為您已付款");
      }

      await fetchOrders();
    }
  };

  return (
    <>
      {loading && <Loading />}
      {showDetailOrders ? (
        <div className="cart-details-container ms-auto me-auto mt-4">
          <div
            className="mb-3 ms-auto me-auto"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              variant="secondary"
              className="me-3"
              style={{ width: "auto" }}
              onClick={() => setShowDetailOrders(false)}
            >
              <IoArrowBackSharp size={20} />
            </Button>
            <h3 style={{ margin: "0", padding: "0", color: "#ccc" }}>
              訂單編號：{detailOrders.self_trade_no}
            </h3>
          </div>
          <Table
            style={{ textAlign: "center" }}
            className="ms-auto me-auto"
            data-bs-theme="dark"
          >
            <thead>
              <tr>
                <th className="mid-lavender-background">圖片</th>
                <th className="mid-lavender-background">名稱</th>
                <th className="mid-lavender-background">磨損度</th>
                <th className="mid-lavender-background">價格</th>
              </tr>
            </thead>
            <tbody>
              {detailOrders.contents.map((order) => (
                <tr key={order.id}>
                  <td className="dark-lavender-background">
                    <img
                      src={imagePrefix + order.icon_url}
                      alt="skin"
                      style={{ width: "70px" }}
                    />
                    <a
                      href={order.inspect_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink style={{ color: "gray" }} />
                    </a>
                  </td>
                  <td className="dark-lavender-background">
                    {order.item_name}
                  </td>
                  <td className="text-start dark-lavender-background">
                    Float: {order.float_value}
                    <FloatVisualizer float={order.float_value} />
                  </td>
                  <td className="dark-lavender-background">
                    NTD {order.price}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="cart-details-container ms-auto me-auto mt-4">
          {unfinishedOrders.length > 0 && (
            <>
              <Row className="mb-3 ms-auto me-auto">
                <Col xs={8}>
                  <h3 style={{ color: "#ccc" }}>進行中訂單</h3>
                </Col>
              </Row>
              <Table
                style={{ textAlign: "center" }}
                className="ms-auto me-auto"
                data-bs-theme="dark"
              >
                <thead>
                  <tr>
                    <th className="mid-lavender-background">訂單編號</th>
                    <th className="mid-lavender-background">建立日期</th>
                    <th className="mid-lavender-background">總金額</th>
                    <th className="mid-lavender-background">付款資訊</th>
                    <th className="mid-lavender-background">付款期限</th>
                    <th className="mid-lavender-background">付款狀態</th>
                    <th className="mid-lavender-background"></th>
                  </tr>
                </thead>
                <tbody>
                  {unfinishedOrders.map((order) => (
                    <tr key={order.id}>
                      <td className="dark-lavender-background">
                        {order.self_trade_no}
                      </td>
                      <td className="dark-lavender-background">
                        {order.readableCreatedAt}
                      </td>
                      <td className="dark-lavender-background">
                        NTD {order.price}
                      </td>
                      <td className="dark-lavender-background">
                        {paymentMethodCodeMap[order.payment_method_code]}
                        {order.payment_method_code === 0
                          ? `付款代碼：${order.paymentNo}`
                          : order.payment_method_code === 1
                          ? ` ${BankCodeToNameMap[order.bankCode]} ${
                              order.vAccount
                            }`
                          : ""}
                      </td>
                      <td
                        style={{ color: "red" }}
                        className="dark-lavender-background"
                      >
                        {order.readableExpiredAt}
                      </td>
                      <td className="dark-lavender-background">
                        {paymentStatusCodeMap[order.payment_status_code]}
                      </td>
                      <td className="dark-lavender-background">
                        <Button
                          className="me-3"
                          onClick={() => {
                            setDetailOrders(order);
                            setShowDetailOrders(true);
                          }}
                          variant="outline-primary"
                        >
                          檢視
                        </Button>
                        <Button
                          onClick={() => handleCancelOrder(order)}
                          variant="outline-danger"
                        >
                          取消
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {finishedOrders.length > 0 && (
            <>
              <Row className="mb-3 mt-5 ms-auto me-auto">
                <Col xs={8}>
                  <h3 style={{ color: "#ccc" }}>已完成訂單</h3>
                </Col>
              </Row>
              <Table
                style={{ textAlign: "center" }}
                className="ms-auto me-auto"
                data-bs-theme="dark"
              >
                <thead>
                  <tr>
                    <th className="mid-lavender-background">訂單編號</th>
                    <th className="mid-lavender-background">建立日期</th>
                    <th className="mid-lavender-background">總金額</th>
                    <th className="mid-lavender-background">付款方式</th>
                    <th className="mid-lavender-background">最後更新時間</th>
                    <th className="mid-lavender-background">狀態</th>
                    <th className="mid-lavender-background"></th>
                  </tr>
                </thead>
                <tbody>
                  {finishedOrders.map((order) => (
                    <tr key={order.id}>
                      <td className="dark-lavender-background">
                        {order.self_trade_no}
                      </td>
                      <td className="dark-lavender-background">
                        {order.readableCreatedAt}
                      </td>
                      <td className="dark-lavender-background">
                        {order.price}
                      </td>
                      <td className="dark-lavender-background">
                        {paymentMethodCodeMap[order.payment_method_code]}
                      </td>
                      <td className="dark-lavender-background">
                        {order.readableLastModifiedAt}
                      </td>
                      <td className="dark-lavender-background">
                        {paymentStatusCodeMap[order.payment_status_code]}
                      </td>
                      <td className="dark-lavender-background">
                        <Button
                          onClick={() => {
                            setDetailOrders(order);
                            setShowDetailOrders(true);
                          }}
                          variant="outline-primary"
                        >
                          檢視
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {finishedOrders.length === 0 && unfinishedOrders.length === 0 && (
            <h3
              className="ms-auto me-auto mt-5"
              style={{ width: "100%", textAlign: "center", color: "#ccc" }}
            >
              您目前沒有訂單紀錄。
            </h3>
          )}
        </div>
      )}
    </>
  );
};

export default OrderDetails;
