import { createSlice } from '@reduxjs/toolkit'

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    value: {},
  },
  reducers: {
    updateInventory: (state, action) => {
      state.value = action.payload;
    },
  }
})

export const { updateInventory } = inventorySlice.actions

export const selectInventory = (state) => state.inventory.value

export default inventorySlice.reducer