import Cover from "../../component/Cover";
import MySaleRecord from "./component/MySaleRecord";
import "../style.css";

const MySale = () => {
  return (
    <div className="basic-page no-main-navbar">
      <Cover title="我的出售" />
      <MySaleRecord />
    </div>
  );
};

export default MySale;
