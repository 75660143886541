import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * The visitor tries to access a non-existent page.
 * Redirect the visitor to the home page.
 */
const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);
};

export default NotFound;
