import { createSlice } from '@reduxjs/toolkit'

export const searchKeySlice = createSlice({
  name: 'searchKey',
  initialState: {
    value: '',
    trigger: false
  },
  reducers: {
    updateSearchKey: (state, action) => {
      state.value = action.payload;
    },
    updateTrigger: (state) => {
      state.trigger = !state.trigger;
    }
  }
})

export const { updateSearchKey, updateTrigger } = searchKeySlice.actions

export const selectSearchKey = (state) => state.searchKey.value
export const selectSearchTrigger = (state) => state.searchKey.trigger

export default searchKeySlice.reducer