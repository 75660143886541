import { useState } from "react";
import { GiM3GreaseGun } from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";
import { typeTranslationMap } from "../../util/mapping";
import { useDispatch } from "react-redux";
import {
  updateSearchKey,
  updateTrigger,
} from "../../features/searchKey/searchKey";
import "./style.css";
import { useNavigate } from "react-router-dom";

/**
 * Designed for mid-tier weapons dropdown.
 */
const MidTierDropdown = ({ title, options }) => {
  // Whether the mouse is hovering on the dropdown.
  const [isHovering, setIsHovering] = useState(false);

  // The index of the option that the mouse is hovering on, and -1 means no option is being hovered.
  const [hoveringIndex, setHoveringIndex] = useState(-1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * When mouse leaves the dropdown, set isHovering to false and reset hoveringIndex.
   */
  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoveringIndex(-1);
  };

  /**
   * When clicking on an option, navigate to the corresponding page.
   */
  const handleClickOption = (name) => {
    dispatch(updateSearchKey(name));
    navigate("/");
    dispatch(updateTrigger());
  };

  return (
    <div
      className="custom-dropdown mid-tier"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ position: "relative", width: "100%" }}
        className="d-flex align-items-center"
      >
        <GiM3GreaseGun color="#ccc" size={25} />
        <p className="ms-1 me-3" style={{ margin: "0", color: "#ccc" }}>
          {title}
        </p>
        <GoTriangleRight
          style={{
            position: "absolute",
            right: "0",
            bottom: "-5px",
            transform: "rotate(45deg)",
          }}
          color="#ccc"
        />
      </div>
      {isHovering && (
        <div className="options" style={{ width: "150px" }}>
          {Object.keys(options).map((key, id) => (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "relative",
                }}
                className="option"
                key={id}
                onMouseEnter={() => setHoveringIndex(id)}
              >
                <p style={{ margin: "0" }}>{typeTranslationMap[key]}</p>
                <GoTriangleRight />
              </div>
              {hoveringIndex >= 0 && (
                <div
                  className="sub-options"
                  style={{ top: `${hoveringIndex * 50}px` }}
                >
                  {options[Object.keys(options)[hoveringIndex]].map(
                    (option, index) => (
                      <p
                        className="sub-option"
                        key={index}
                        style={{ margin: "0" }}
                        onClick={() => handleClickOption(option.name)}
                      >
                        {option.name}
                      </p>
                    )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MidTierDropdown;
