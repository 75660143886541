import Cover from "../../component/Cover";
import OrderDetails from "./component/OrderDetails";

const Orders = () => {
  return (
    <div className="basic-page no-main-navbar">
      <Cover title="訂單紀錄" />
      <OrderDetails />
    </div>
  );
};

export default Orders;
