import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateLogin } from "../features/login/login";

const LoginCB = () => {
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    dispatch(updateLogin(true));
    cookies.set("session_key", searchParams.get("session_key"), {
      maxAge: 360000 * 12,
    });
    navigate("/");
  }, []);
};

export default LoginCB;
