import { useEffect, useState } from "react";
import "./style.css";
import { GoTriangleRight } from "react-icons/go";

/**
 * Designed for all selects in the filter component.
 */
const Select = ({
  title,
  options,
  setValue,
  canShowOptions,
  defaultExterior,
}) => {
  // The text displayed on the header of the select.
  // It is by default the title, but will change when an option is selected.
  const [headerText, setHeaderText] = useState(title);

  // Whether the options are shown.
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (!canShowOptions) {
      setShowOptions(false);
    }
  }, [canShowOptions]);

  useEffect(() => {
    if (!defaultExterior) {
      return;
    }

    for (const key of Object.keys(options)) {
      if (options[key] === defaultExterior) {
        setHeaderText(key);
        break;
      }
    }
  }, [defaultExterior]);

  return (
    <div style={{ width: "150px", position: "relative", zIndex: "10" }}>
      <div className="header" onClick={() => setShowOptions(!showOptions)}>
        <p style={{ margin: "0", color: "#ccc", marginLeft: "10px" }}>
          {headerText}
        </p>
        <GoTriangleRight
          style={{
            position: "absolute",
            right: "3px",
            bottom: "3px",
            transform: "rotate(45deg)",
          }}
          color="#ccc"
        />
      </div>
      {showOptions && (
        <div className="select-options">
          {Object.keys(options).map((key, id) => (
            <div
              key={id}
              className="select-option"
              onClick={() => {
                setHeaderText(key);
                setShowOptions(false);
                setValue(options[key]);
              }}
            >
              {key}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
