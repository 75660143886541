import { TiArrowSortedDown } from "react-icons/ti";
import { useEffect, useState } from "react";
import "./style.css";

/**
 * 顯示磨損度的視覺化元件
 */
const FloatVisualizer = ({ float }) => {
  const [leftMargin, setLeftMargin] = useState(0);

  useEffect(() => {
    setLeftMargin(float * 100);
  }, [float]);

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        height: "15px",
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      {float >= 0 && (
        <TiArrowSortedDown
          size={15}
          style={{
            position: "absolute",
            top: "0px",
            left: `calc(${leftMargin}% - 7.5px)`,
          }}
        />
      )}
      <div className="visualizer"></div>
    </div>
  );
};

export default FloatVisualizer;
