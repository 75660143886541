/**
 * 銀行代碼對應名稱
 */
export const BankCodeToNameMap = {
  '004': '臺灣銀行',
  '005': '臺灣土地銀行',
  '006': '合作金庫',
  '007': '第一商業銀行',
  '008': '華南商業銀行',
  '009': '彰化商業銀行',
  '011': '上海商業儲蓄銀行',
  '012': '台北富邦商業銀行',
  '013': '國泰世華商業銀行',
  '015': '中國輸出入銀行',
  '016': '高雄銀行',
  '017': '兆豐國際商業銀行',
  '020': '瑞穗銀行',
  '021': '花旗商業銀行',
  '022': '美國銀行',
  '023': '泰國盤谷銀行',
  '025': '菲律賓首都銀行',
  '028': '美國紐約梅隆銀行',
  '029': '大華銀行有限公司',
  '030': '道富銀行',
  '037': '法國興業銀行',
  '039': '澳盛銀行集團',
  '048': '王道商業銀行',
  '050': '臺灣中小企業銀行',
  '052': '渣打國際商業銀行',
  '053': '台中商業銀行',
  '054': '京城商業銀行',
  '072': '德意志銀行',
  '075': '東亞銀行有限公司',
  '076': '摩根大通銀行',
  '078': '新加坡商星展銀行台北分行',
  '081': '滙豐商業銀行',
  '082': '法國巴黎銀行',
  '083': '渣打銀行',
  '085': '新加坡華僑銀行',
  '086': '東方匯理銀行',
  '092': '瑞士銀行',
  '093': '安智銀行',
  '097': '富國銀行',
  '098': '三菱日聯銀行',
  '101': '瑞興商業銀行',
  '102': '華泰商業銀行',
  '103': '臺灣新光商業銀行',
  '108': '陽信商業銀行',
  '118': '板信商業銀行',
  '147': '三信商業銀行',
  '321': '三井住友銀行',
  '324': '花旗銀行',
  '325': '香港上海滙豐銀行',
  '326': '西班牙對外銀行',
  '328': '法國外貿銀行',
  '329': '印尼人民銀行',
  '330': '韓亞銀行',
  '700': '中華郵政',
  '803': '聯邦商業銀行',
  '805': '遠東國際商業銀行',
  '806': '元大商業銀行',
  '807': '永豐商業銀行',
  '808': '玉山商業銀行',
  '809': '凱基商業銀行',
  '810': '星展(台灣)商業銀行',
  '812': '台新國際商業銀行',
  '816': '安泰商業銀行',
  '822': '中國信託',
  '823': '將來商業銀行',
  '824': '連線商業銀行',
  '826': '樂天國際商業銀行',
};