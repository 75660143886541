import { Table } from "react-bootstrap";
import { useState } from "react";
import Cover from "../../component/Cover";

/**
 * 提領紀錄頁面，此頁面只有完成基本的 UI。
 */
const WithdrawRecord = () => {
  const [withdrawRecord, _] = useState([
    {
      time: "2023/08/13 13:33",
      amount: "400",
      method: "玉山銀行 808-0123456789",
      status: "成功",
    },
    {
      time: "2023/08/14 16:23",
      amount: "500",
      method: "郵局 700-0123456789",
      status: "成功",
    },
    {
      time: "2023/08/15 17:30",
      amount: "600",
      method: "中國信託 822-0123456789",
      status: "成功",
    },
  ]);

  return (
    <div className="basic-page no-main-navbar">
      <Cover title="提領紀錄" />
      <div
        className="mt-5"
        style={{ marginLeft: "10%", width: "80%" }}
      >
        <Table className="ms-auto me-auto" data-bs-theme="dark">
          <thead>
            <tr>
              <th className="mid-lavender-background">時間</th>
              <th className="mid-lavender-background">金額</th>
              <th className="mid-lavender-background">方式</th>
              <th className="mid-lavender-background">狀態</th>
            </tr>
          </thead>
          <tbody>
            {withdrawRecord.map((ele) => (
              <tr>
                <td className="dark-lavender-background">{ele.time}</td>
                <td className="dark-lavender-background">{ele.amount}</td>
                <td className="dark-lavender-background">{ele.method}</td>
                <td className="dark-lavender-background">{ele.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default WithdrawRecord;
