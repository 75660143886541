import { useEffect, useState } from "react";
import { Table, Button, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { imagePrefix } from "../../../util/imagePrefix";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectDisplaySkin } from "../../../features/displaySkin/displaySkin";
import {
  extractImageLinks,
  extractImageName,
} from "../../../util/getStickerLink";
import { FiExternalLink } from "react-icons/fi";
import { selectUser } from "../../../features/user/user";
import { FaCartPlus } from "react-icons/fa";
import Filter from "../../../component/Filter";
import Loading from "../../../component/Loading";
import FloatVisualizer from "../../../component/FloatVisualizer";
import PurchaseModal from "../../../component/PurchaseModal";
import { steamLogin } from "../../../util/login";
import { getCookie } from "../../../util/getCookie";
import { api } from "../../../config/api";
import { addBaseTagToHTML, openHtml } from "../../../util/paymentHtml";
import showDialog from "../../../component/Dialog/showDialog";

const SkinList = () => {
  const [skins, setSkins] = useState([]);
  const [allSkins, setAllSkins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [steamIdToUsernameMap, setSteamIdToUsernameMap] = useState({});

  // 是否顯示選擇付款方式的彈窗
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  // 要直接購買的物品
  const [purchaseSkins, setPurchaseSkins] = useState([]);

  // 付款方式
  const [purchaseMethod, setPurchaseMethod] = useState("");

  // 購物車物品清單
  const [shoppingCartItems, setShoppingCartItems] = useState([]);

  const skin = useSelector(selectDisplaySkin);
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  /**
   * Get bag items with filters.
   */
  const getBagItems = async (filters = {}) => {
    setLoading(true);

    const searchKey = encodeURIComponent(skin.item_name);

    const allItems = await fetch(
      `${api.skins.all}?searchKey=${searchKey}&rarityId=${
        filters.rarity || ""
      }&qualityId=${filters.quality || ""}&exteriorId=${filters.exterior || ""}`
    ).then((res) => res.json());

    const { skins: skins_ } = allItems;

    // Only display skins with market_status_code = 0 which means it can be sold.
    const sellingSkins = skins_.filter((skin) => skin.market_status_code === 0);

    sellingSkins.forEach((sellingSkin) => {
      const imageLinks = extractImageLinks(sellingSkin.sticker);
      const imageNames = extractImageName(sellingSkin.sticker);
      let images = [];
      for (let i = 0; i < imageLinks.length; i++) {
        images.push({
          link: imageLinks[i],
          name: imageNames[i],
        });
      }
      sellingSkin.stickers = images;
    });

    const steamIds = [];

    sellingSkins.forEach((sellingSkin) => {
      if (!steamIds.includes(sellingSkin.account_id_string)) {
        steamIds.push(sellingSkin.account_id_string);
      }
    });

    const steamIdToUsername = await fetch(api.username, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ steam_ids: steamIds }),
    }).then((res) => res.json());

    setSteamIdToUsernameMap(steamIdToUsername);
    setSkins(sellingSkins);
    setAllSkins(sellingSkins);
    setLoading(false);
  };

  /**
   * Filter the skins by price.
   */
  const filterByPrice = async (lowerPrice, upperPrice) => {
    const lowerPrice_ = parseFloat(lowerPrice || 0);
    const upperPrice_ = parseFloat(upperPrice || Infinity);

    if (isNaN(lowerPrice_) || isNaN(upperPrice_)) {
      await showDialog("請輸入有效的價格！");
      return;
    }

    if (lowerPrice_ > upperPrice_) {
      await showDialog("下限價格不能高於上限價格！");
      return;
    }

    const filteredSkins = allSkins.filter(
      (skin) =>
        parseFloat(skin.market_price) >= lowerPrice_ &&
        parseFloat(skin.market_price) <= upperPrice_
    );

    setSkins(filteredSkins);
  };

  /**
   * 將物品加入購物車
   */
  const handleAddToCart = async (skin) => {
    if (Object.keys(user).length === 0) {
      // The user has not logged in.
      await showDialog("請先登入以加入購物車。");
      setLoading(true);
      await steamLogin();
      return;
    }

    setLoading(true);

    const addedToCart = await fetch(api.cart, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({
        assetid: skin.assetid,
      }),
    }).then((res) => res.json());

    if (addedToCart) {
      await fetchShoppingCartItems();
    }

    setLoading(false);

    if (addedToCart) {
      await showDialog("已將商品加入購物車。");
    } else {
      await showDialog("加入失敗，可能其他使用者已下單，請稍後再試。");
    }
  };

  /**
   * 取得購物車物品清單
   */
  const fetchShoppingCartItems = async () => {
    const shoppingCartItems = await fetch(api.cart, {
      headers: {
        Authorization: getCookie("session_key"),
      },
    }).then((res) => res.json());

    setShoppingCartItems(shoppingCartItems);
  };

  /**
   * 按下「立刻購買」
   */
  const handleBuyNow = async (skin) => {
    if (Object.keys(user).length === 0) {
      // The user has not logged in.
      await showDialog("請先登入以購買。");
      setLoading(true);
      await steamLogin();
      return;
    }

    // Check whether the user's phone number has been verified
    if (!user.phone_verified) {
      await showDialog("請先至帳戶設定完成手機號碼驗證", true, "/setting");
      return;
    }

    setShowPurchaseModal(true);
    setPurchaseSkins([
      {
        ...skin,
        selected: true, // PurchaseModal component filters skins by selected.
      },
    ]);
  };

  /**
   * 立刻購買物品
   */
  const handlePurchaseSkins = async () => {
    setLoading(true);

    const assetids = purchaseSkins.map((skin) => skin.assetid);

    // 檢查是否可購買此物品，bag_items_market_info.market_status_code 是否為 0，是的話才能購買，
    // 並且將 code 改成 2 表示正在等候付款，其他人無法購買。
    const canPurchase = await fetch(api.purchase.validate, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ assetids }),
    }).then((res) => res.json());

    if (!canPurchase) {
      await showDialog("不好意思，您選擇的商品已不可購買，請重新選擇");
      setShowPurchaseModal(false);
      await getBagItems();
      return;
    }

    const itemName = purchaseSkins[0].item_name;

    const body = {
      purchaseMethod,
      price: purchaseSkins[0].market_price,
      itemName,
      items: purchaseSkins,
      clientBackUrl: window.location.origin + "/orders",
    };

    const purchaseHTML = await fetch(api.purchase.payment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());

    console.log(purchaseHTML);

    const baseHref = "https://payment-stage.funpoint.com.tw";
    const modifiedHTML = addBaseTagToHTML(purchaseHTML.html, baseHref);
    openHtml(modifiedHTML);
    setLoading(false);
    setShowPurchaseModal(false);

    // 跳轉至訂單紀錄頁面
    navigate("/orders");
  };

  useEffect(() => {
    getBagItems();

    // Fetch the shopping cart items only when the user has logged in.
    if (Object.keys(user).length > 0) {
      fetchShoppingCartItems();
    }
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.name}
    </Tooltip>
  );

  return (
    <>
      <Filter
        getBagItems={getBagItems}
        defaultWornStatus={skin.wornStatus}
        filterByPrice={filterByPrice}
      />
      {showPurchaseModal && (
        <PurchaseModal
          skins={purchaseSkins}
          totalPrice={purchaseSkins[0].market_price}
          purchaseMethod={purchaseMethod}
          setPurchaseMethod={setPurchaseMethod}
          handlePurchaseSkins={handlePurchaseSkins}
          setShowPurchaseModal={setShowPurchaseModal}
        />
      )}
      {loading ? (
        <Loading />
      ) : skins.length > 0 ? (
        <Table
          style={{ width: "80%" }}
          className="ms-auto me-auto mt-3 mb-5"
          data-bs-theme="dark"
        >
          <thead>
            <tr>
              <th className="mid-lavender-background">造型</th>
              <th className="mid-lavender-background">磨損度</th>
              <th className="mid-lavender-background">賣家</th>
              <th className="mid-lavender-background">價格</th>
              <th className="mid-lavender-background"></th>
            </tr>
          </thead>
          <tbody>
            {skins.map((skin, id) => (
              <tr key={id}>
                <td className="dark-lavender-background">
                  <img
                    src={imagePrefix + skin.icon_url}
                    alt="skin"
                    style={{ height: "50px", width: "auto" }}
                    className="me-2"
                  />
                  <a href={skin.inspect_link} target="_blank" rel="noreferrer">
                    <FiExternalLink style={{ color: "gray" }} />
                  </a>
                  <br />
                  {skin.stickers.map((ele, id) => (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 100, hide: 100 }}
                      overlay={renderTooltip({ name: ele.name })}
                    >
                      <img
                        key={id}
                        src={ele.link}
                        alt="sticker"
                        style={{ height: "1.5rem", width: "auto" }}
                      />
                    </OverlayTrigger>
                  ))}
                </td>
                <td
                  className="text-start dark-lavender-background"
                  style={{ fontSize: "14px" }}
                >
                  磨損: {skin.float_value}
                  <FloatVisualizer float={skin.float_value} />
                </td>
                <td className="dark-lavender-background">
                  {steamIdToUsernameMap[skin.account_id_string]}
                </td>
                <td className="dark-lavender-background">
                  NTD {parseFloat(skin.market_price)}
                </td>
                <td className="dark-lavender-background">
                  {shoppingCartItems.find(
                    (item) => item.assetid === skin.assetid && item.active
                  ) ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button variant="warning">立刻購買</Button>
                      <Button
                        variant="outline-warning"
                        onClick={() => handleAddToCart(skin)}
                        disabled
                      >
                        <FaCartPlus size={20} />
                      </Button>
                    </div>
                  ) : skin.account_id_string === user.steam_id ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button variant="warning" disabled>
                        立刻購買
                      </Button>
                      <Button
                        variant="outline-warning"
                        onClick={() => handleAddToCart(skin)}
                        disabled
                      >
                        <FaCartPlus size={20} />
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="warning"
                        onClick={() => handleBuyNow(skin)}
                      >
                        立刻購買
                      </Button>
                      <Button
                        variant="outline-warning"
                        onClick={() => handleAddToCart(skin)}
                      >
                        <FaCartPlus size={20} />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Row className="mb-5 ms-auto me-auto" style={{ width: "fit-content" }}>
          <h3 className="ms-auto me-auto mt-5" style={{ color: "#ccc" }}>
            尚無此造型！
          </h3>
        </Row>
      )}
    </>
  );
};

export default SkinList;
