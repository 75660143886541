import React from "react";
import ReactDOM from "react-dom";
import Dialog from "./Dialog";

const showDialog = (context, showCloseButton = true, href = "") => {
  return new Promise((resolve) => {
    const div = document.createElement("div");
    document.body.appendChild(div);

    const handleDisappear = () => {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
      resolve();
    };

    ReactDOM.render(
      <Dialog
        context={context}
        showCloseButton={showCloseButton}
        href={href}
        onDisappear={handleDisappear}
      />,
      div
    );
  });
};

export default showDialog;
