import { Nav, Navbar, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { selectLogin } from "../features/login/login";
import { selectUser, updateUser } from "../features/user/user";
import { updateInventory } from "../features/inventory/inventory";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { updateSearchKey } from "../features/searchKey/searchKey";
import { updateTrigger } from "../features/searchKey/searchKey";
import { updateLogin } from "../features/login/login";
import Loading from "./Loading";
import { steamLogin } from "../util/login";
import Dropdown from "./Dropdown/Dropdown";
import { api } from "../config/api";
import "./style.css";

const TopNavbar = () => {
  const loginState = useSelector(selectLogin);
  const user = useSelector(selectUser);

  // 是否正在等待跳轉登入頁面
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Login with Steam
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await steamLogin();
  };

  /**
   * Logout
   */
  const handleLogout = async (e) => {
    e.preventDefault();
    setLoading(true);

    await fetch(api.logout, {
      headers: {
        Authorization: Cookies.get("session_key"),
      },
    });

    dispatch(updateUser({}));
    dispatch(updateInventory({}));
    dispatch(updateLogin(false));

    if (Cookies.get("session_key")) {
      Cookies.remove("session_key");
    }

    navigate("/");
    window.location.reload();
  };

  const handleClickBrand = () => {
    dispatch(updateSearchKey(""));
    dispatch(updateTrigger());
    navigate("/");
  };

  return (
    <>
      {loading && <Loading />}
      <Navbar className="top-navbar">
        <Nav
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <Navbar.Brand>
            <Image
              className="logo"
              src="logo.png"
              style={{ width: "40px" }}
              onClick={handleClickBrand}
            />
          </Navbar.Brand>
          {loginState ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Nav.Item>
                <Nav.Link href="/" style={{ color: "#ccc" }}>
                  商城
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {user.user_group_id === 1 && <Nav.Link href="/admin" style={{ color: "#ccc" }}>
                  Admin
                </Nav.Link>}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/shopping-cart" style={{ color: "#ccc" }}>
                  購物車
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/orders" style={{ color: "#ccc" }}>
                  訂單紀錄
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/my-sale" style={{ color: "#ccc" }}>
                  我的出售
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/my-skin" style={{ color: "#ccc" }}>
                  我的物品庫
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={handleLogout} style={{ color: "#ccc" }}>
                  登出
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={`https://avatars.akamai.steamstatic.com/${user.avatarhash}.jpg`}
                  style={{ width: "20px", height: "20px" }}
                  className="ms-3 me-1"
                />
                <Dropdown
                  title={user.username}
                  options={[
                    {
                      name: "提領",
                      href: "/withdraw",
                    },
                    {
                      name: "提領紀錄",
                      href: "/withdraw-record",
                    },
                    {
                      name: "帳戶設定",
                      href: "/setting",
                    },
                  ]}
                />
              </Nav.Item>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Nav.Item>
                <Nav.Link onClick={handleLogin}>
                  <img src="steam-login.png" style={{ height: "35px" }} />
                </Nav.Link>
              </Nav.Item>
            </div>
          )}
        </Nav>
      </Navbar>
    </>
  );
};

export default TopNavbar;
