// The stage of TWSKINS APIs, which is either 'dev' or 'prod'.
const stage = 'dev';

// The base URL of TWSKINS APIs.
const apiGatewayUrl = `https://09ug8042ja.execute-api.ap-northeast-1.amazonaws.com/${stage}`;

/**
 * All endpoints of TWSKINS APIs.
 */
export const api = {
  admin: {
    nationalId: `${apiGatewayUrl}/admin/national-id`,
  },
  cart: `${apiGatewayUrl}/cart`,
  images: `${apiGatewayUrl}/images`,
  login: {
    login: `${apiGatewayUrl}/login`,
    verify: `${apiGatewayUrl}/login/verify`,
  },
  logout: `${apiGatewayUrl}/logout`,
  orders: `${apiGatewayUrl}/orders`,
  purchase: {
    orders: `${apiGatewayUrl}/purchase/orders`,
    payment: `${apiGatewayUrl}/purchase`,
    validate: `${apiGatewayUrl}/purchase/validate`,
  },
  skins: {
    all: `${apiGatewayUrl}/skins`,
    float: `${apiGatewayUrl}/skins/float`,
    marketInfo: `${apiGatewayUrl}/skins/market-info`,
    steamInventory: `${apiGatewayUrl}/skins/user/steam-inventory`,
    user: `${apiGatewayUrl}/skins/user`,
  },
  types: `${apiGatewayUrl}/types`,
  userInfo: {
    email: {
      update: `${apiGatewayUrl}/user-info/email`,
      verify: `${apiGatewayUrl}/user-info/email/verify`,
    },
    nationalId: {
      update: `${apiGatewayUrl}/user-info/national-id`,
      verify: `${apiGatewayUrl}/user-info/national-id/verify`,
    },
    phone: {
      update: `${apiGatewayUrl}/user-info/phone`,
      verify: `${apiGatewayUrl}/user-info/phone/verify`,
    },
    tradeurl: `${apiGatewayUrl}/user-info/tradeurl`,
  },
  username: `${apiGatewayUrl}/username`,
};
