import { FaCircleInfo } from "react-icons/fa6";
import { useState } from "react";
import { Button } from "react-bootstrap";
import "./style.css";

const Dialog = ({ context, showCloseButton, href, onDisappear }) => {
  const [visible, setVisible] = useState(true);

  const disappear = () => {
    setVisible(false);

    if (onDisappear) {
      onDisappear();
    }
  }

  if (!visible) return null;

  return (
    <div className="dialog-container">
      <div className="dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <FaCircleInfo size={25} className="me-2" />
          <h5 style={{ margin: "0", marginBottom: "6px" }}>TWSKINS 通知</h5>
        </div>
        <hr style={{ border: "1px solid white", width: "100%" }} />
        <div className="mt-3 mb-3">
          <p style={{ margin: "0", marginBottom: "4px" }}>{context}</p>
        </div>
        {showCloseButton && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            {href.length ? (
              <Button>
                <a
                  href={href}
                  style={{ color: "#ccc", textDecoration: "none" }}
                >
                  立即前往
                </a>
              </Button>
            ) : (
              <Button onClick={disappear}>確認</Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dialog;
