/**
 * 身分證字號的第一個字母對應的數字
 */
const characterToNumber = {
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 34,
  J: 18,
  K: 19,
  L: 20,
  M: 21,
  N: 22,
  O: 35,
  P: 23,
  Q: 24,
  R: 25,
  S: 26,
  T: 27,
  U: 28,
  V: 29,
  W: 32,
  X: 30,
  Y: 31,
  Z: 33,
};

/**
 * 驗證身分證字號是否符合規則
 * @param {*} nationalId 身分證字號
 */
export const verifyNationalId = (nationalId) => {
  if (nationalId.length !== 10) {
    return false;
  }

  const characterNumber = characterToNumber[nationalId[0]];

  if (!characterNumber) {
    return false;
  }

  const characterNumberString = characterNumber.toString();

  let checkNumber = parseInt(characterNumberString[0], 10) + parseInt(characterNumberString[1], 10) * 9;

  for (let i = 1; i < 9; i++) {
    checkNumber += parseInt(nationalId[i], 10) * (9 - i);
  }

  const remaining = checkNumber % 10;

  const lastNumber = 10 - remaining;

  if (lastNumber === parseInt(nationalId[9], 10)) {
    return true;
  }

  return false;
}
