import { useEffect } from "react";
import MainNavbar from "../../component/MainNavbar";
import Cover from "../../component/Cover";
import DisplaySkin from "./component/DisplaySkin";
import { useSelector, useDispatch } from "react-redux";
import { selectLogin } from "../../features/login/login";
import { selectUser } from "../../features/user/user";
import {
  selectInventory,
  updateInventory,
} from "../../features/inventory/inventory";
import { extractImageLinks, extractImageName } from "../../util/getStickerLink";
import { getCookie } from "../../util/getCookie";
import { api } from "../../config/api";
import "../style.css";

const Home = () => {
  const login = useSelector(selectLogin);
  const inventory = useSelector(selectInventory);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  /**
   * Get the full inspect link with actual assetid and owner_id (steam_id)
   * @param {*} link original inspect link
   * @param {*} id the index of the item in the inventory
   */
  const getFullInspectLink = (link, assetid) => {
    const steam_id = user.steam_id;
    const withSteamID = link.replace(/%owner_steamid%/g, steam_id);
    const withAll = withSteamID.replace(/%assetid%/g, assetid);
    return withAll;
  };

  /**
   * Because getting Steam inventory is time-consuming, here we do it in the background.
   */
  const getSteamInventoryInBackground = async () => {
    if (!login || inventory.assets !== undefined) {
      console.log("No need to fetch Steam inventory");
      console.log(login);
      console.log(inventory.assets);
      return;
    }

    console.log("Start fetching Steam inventory");

    const skinsInTwskins = await fetch(api.skins.user, {
      headers: new Headers({
        Authorization: getCookie("session_key"),
      }),
    }).then((res) => res.json());

    let response = {};

    try {
      response = await fetch(api.skins.steamInventory, {
        headers: {
          Authorization: getCookie("session_key"),
        },
      }).then((res) => res.json());
    } catch (error) {
      console.log(error);
    }

    if (
      response.successful &&
      response.data.total_inventory_count > 0 &&
      response.data.descriptions.length > 0
    ) {
      const assets = response.data.assets;
      let descriptions = response.data.descriptions;

      const inspectLinks = [];

      for (let i = 0; i < descriptions.length; ++i) {
        const actions = descriptions[i].actions;

        if (!actions) {
          inspectLinks.push("");
          continue;
        }

        const inspectLink = getFullInspectLink(
          actions[0].link,
          assets[i].assetid
        );
        inspectLinks.push(inspectLink);

        if (inspectLinks.length === 5 || i === descriptions.length - 1) {
          try {
            const floatResponse = await fetch(api.skins.float, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: getCookie("session_key"),
              },
              body: JSON.stringify({
                inspectLinks,
              }),
            }).then((res) => res.json());

            for (let j = 0; j < floatResponse.floats.length; ++j) {
              descriptions[i - inspectLinks.length + 1 + j].float =
                floatResponse.floats[j];
            }

            inspectLinks.splice(0, inspectLinks.length);
          } catch (error) {
            for (let j = 0; j < inspectLinks.length; ++j) {
              descriptions[i - inspectLinks.length + 1 + j].float = -1;
            }

            inspectLinks.splice(0, inspectLinks.length);
          }
        }
      }

      for (const description of descriptions) {
        if (description.descriptions.length < 7) {
          continue;
        }
        const stickers = description.descriptions[6]?.value;
        if (!stickers) continue;
        const imageLinks = extractImageLinks(stickers);
        const imageNames = extractImageName(stickers);
        let images = [];
        for (let i = 0; i < imageLinks.length; i++) {
          images.push({
            link: imageLinks[i],
            name: imageNames[i],
          });
        }
        description.stickers = images;
      }

      const newDescriptions = descriptions.map((obj) => ({
        ...obj,
        selected: false,
        price: "",
      }));

      for (let i = 0; i < newDescriptions.length; i++) {
        for (let j = 0; j < skinsInTwskins.skins.length; j++) {
          if (
            inventory.assets &&
            inventory.assets[i].assetid ===
              skinsInTwskins.skins[j].assetid.toString() &&
            skinsInTwskins.skins[j].market_status_code === 0
          ) {
            newDescriptions[i].deposited = true;
            newDescriptions[i].price = +skinsInTwskins.skins[j].market_price;
            break;
          }
          if (j === skinsInTwskins.skins.length - 1) {
            newDescriptions[i].deposited = false;
          }
        }
      }

      dispatch(
        updateInventory({
          descriptions: descriptions,
          assets: response.data.assets,
        })
      );

      console.log("Finish fetching Steam inventory");
    }
  };

  useEffect(() => {
    // getSteamInventoryInBackground();
  }, []);

  return (
    <div className="basic-page">
      <MainNavbar />
      <Cover />
      <DisplaySkin />
    </div>
  );
};

export default Home;
