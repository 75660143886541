import { Badge, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { update } from "../../../features/displaySkin/displaySkin";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  badgeColorMap,
  badgeTextColorMap,
  wornStatusTranslationMap,
} from "../../../util/mapping";
import { imagePrefix } from "../../../util/imagePrefix";
import FloatVisualizer from "../../../component/FloatVisualizer";
import "../../style.css";

const DisplaySkinItem = ({ skin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [exteriorId, setExteriorId] = useState(0);

  /**
   * After clicking on a skin, navigate to the skin list page.
   */
  const handleClickSkin = () => {
    dispatch(update({ item_name: skin.item_name, wornStatus: exteriorId }));
    navigate("/skin-list");
  };

  useEffect(() => {
    setExteriorId(skin.exterior_id);
  }, [skin]);

  return (
    <Card className="skin mt-4 mb-5" onClick={handleClickSkin}>
      <Badge
        className="mt-2 ms-2"
        bg={badgeColorMap[exteriorId]}
        text={badgeTextColorMap[exteriorId]}
        style={{ fontSize: "13px", width: "fit-content" }}
      >
        {wornStatusTranslationMap[exteriorId]}
      </Badge>
      <Card.Img
        src={imagePrefix + skin.icon_url}
        style={{ width: "80%" }}
        className="ms-auto me-auto skin-image"
      />
      <Card.Body className="skin-details">
        <div className="float">
          磨損: {skin.float_value}
          <FloatVisualizer float={skin.float_value} />
        </div>
        <Card.Title
          className="mt-2 mb-1"
          style={{ fontSize: "16px", width: "fit-content", color: "#ddd" }}
        >
          {skin.item_name}
        </Card.Title>
        <Card.Text
          style={{ color: "#ff8800", fontWeight: "bold", width: "fit-content" }}
        >
          NT$ {skin.market_price || "--"}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DisplaySkinItem;
