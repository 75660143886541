import Cover from "../../component/Cover";
import MySkin from "./component/MySkin";
import "../style.css";

const MySkinStorage = () => {
  return (
    <div className="basic-page no-main-navbar">
      <Cover title="我的物品庫" />
      <MySkin />
    </div>
  );
};

export default MySkinStorage;
