import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  selectSearchKey,
  selectSearchTrigger,
} from "../features/searchKey/searchKey";

const Cover = (props) => {
  const searchKey = useSelector(selectSearchKey);
  const trigger = useSelector(selectSearchTrigger);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(
      searchKey || props.item?.item_name || props.title || "TWSKINS 台灣造型網"
    );
  }, [searchKey, trigger]);

  return (
    <Card className="cover d-flex justify-content-center">
      <h2 style={{ fontSize: "35px", color: "#ccc" }}>{title}</h2>
      <h4 style={{ fontSize: "25px", color: "#ccc" }} className="mt-2">
        Make your CS:GO <a className="colorful">COLORFUL.</a>
      </h4>
    </Card>
  );
};

export default Cover;
