import { useState } from "react";
import { FaGun } from "react-icons/fa6";
import {
  GiWinchesterRifle,
  GiCurvyKnife,
  GiWinterGloves,
} from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";
import { FaUserNinja } from "react-icons/fa";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSearchKey, updateTrigger } from "../../features/searchKey/searchKey";

/**
 * Designed for all dropdowns, including main navbar and filters.
 */
const Dropdown = ({ title, options }) => {
  // Whether the mouse is hovering on the dropdown.
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * When the mouse enters the dropdown, set isHovering to true.
   */
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  /**
   * When the mouse leaves the dropdown, set isHovering to false.
   */
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  /**
   * When clicking on an option, navigate to the corresponding page.
   * @param {*} href the href of the corresponding page.
   */
  const handleClickOption = (option) => {
    if (option.href) {
      navigate(option.href);
      return;
    }

    dispatch(updateSearchKey(option.name));
    navigate("/");
    dispatch(updateTrigger());
  };

  return (
    <div
      className="custom-dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ position: "relative", width: "100%" }}
        className="d-flex align-items-center"
      >
        {title === "手槍" && <FaGun color="#ccc" />}
        {title === "步槍" && <GiWinchesterRifle color="#ccc" size={20} />}
        {title === "近戰武器" && <GiCurvyKnife color="#ccc" size={15} />}
        {title === "手套" && <GiWinterGloves color="#ccc" size={15} />}
        {title === "幹員" && <FaUserNinja color="#ccc" size={15} />}
        <p className="ms-1 me-3" style={{ margin: "0", color: "#ccc" }}>
          {title}
        </p>
        <GoTriangleRight
          style={{
            position: "absolute",
            right: "0",
            bottom: "-5px",
            transform: "rotate(45deg)",
          }}
          color="#ccc"
        />
      </div>
      {isHovering && (
        <div
          className="options"
          style={{
            width:
              title === "手套"
                ? "200px"
                : title === "近戰武器"
                ? "180px"
                : "150px",
            height: title === "近戰武器" ? "600px" : "",
            overflowY: title === "近戰武器" ? "scroll" : "",
          }}
        >
          {options.map((option, id) => (
            <div key={id}>
              {option.name === "Zeus x27" && (
                <hr style={{ margin: "0px", color: "white" }} />
              )}
              <p
                className="option"
                key={id}
                onClick={() => handleClickOption(option)}
              >
                {option.name}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
