import { useEffect } from "react";
import Cover from "../../component/Cover";
import AdminDetail from "./component/AdminDetail";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user/user";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.user_group_id !== 1) {
      navigate("/");
    }
  }, []);

  return (
    <div className="basic-page no-main-navbar">
      <Cover title="Admin" />
      <AdminDetail />
    </div>
  );
};

export default Admin;
