import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import Loading from "../../../component/Loading";
import showDialog from "../../../component/Dialog/showDialog";
import { api } from "../../../config/api";
import { getCookie } from "../../../util/getCookie";

/**
 * 提供管理員進行身分證驗證的頁面
 */
const IdVerification = (props) => {
  // 使用者的身分證資料
  const [ids, setIds] = useState([]);

  // 是否正在載入
  const [loading, setLoading] = useState(true);

  // 是否顯示拒絕身分證驗證的彈窗
  const [showRejectModal, setShowRejectModal] = useState(false);

  // 被拒絕的身分證的使用者的 avatarhash
  const [rejectingAvatarHash, setRejectingAvatarHash] = useState("");

  // 拒絕的原因
  const [rejectReason, setRejectReason] = useState("");

  /**
   * 取得所有使用者的身分證資料
   */
  const fetchIds = async () => {
    setLoading(true);

    const ids_ = await fetch(api.admin.nationalId, {
      headers: {
        Authorization: getCookie("session_key"),
      },
    }).then((res) => res.json());

    const filenames = ids_.map((id) => id.id_filename).join(",");

    const files = await fetch(`${api.images}?filenames=${filenames}`, {
      headers: {
        Authorization: getCookie("session_key"),
      },
    }).then((res) => res.json());

    for (let i = 0; i < files.length; i++) {
      ids_[i].url = files[i];
    }

    let result = [...ids_];

    if (props.name || props.id) {
      const filteredIds = ids_.filter(
        (account) =>
          (!props.name ||
            account.last_name.includes(props.name) ||
            account.first_name.includes(props.name) ||
            `${account.last_name}${account.first_name}`.includes(props.name)) &&
          (!props.id || account.national_id.includes(props.id))
      );
      result = [...filteredIds];
    }

    if (props.verifiedStatusFilter !== "all") {
      const filteredIds = result.filter(
        (account) =>
          (props.verifiedStatusFilter === "pending" &&
            account.national_id_status === 0) ||
          (props.verifiedStatusFilter === "verified" &&
            account.national_id_status === 1) ||
          (props.verifiedStatusFilter === "rejected" &&
            account.national_id_status === 2)
      );
      result = [...filteredIds];
    }

    if (props.timeOrderFilter === "new-to-old") {
      result.sort((a, b) => {
        const timeA = moment(
          convertTime(a.id_filename.split(".")[0].slice(-19))
        );
        const timeB = moment(
          convertTime(b.id_filename.split(".")[0].slice(-19))
        );
        return timeB.isAfter(timeA) ? 1 : -1;
      });
    } else {
      result.sort((a, b) => {
        const timeA = moment(
          convertTime(a.id_filename.split(".")[0].slice(-19))
        );
        const timeB = moment(
          convertTime(b.id_filename.split(".")[0].slice(-19))
        );
        return timeB.isAfter(timeA) ? -1 : 1;
      });
    }

    setIds(result);
    setLoading(false);
  };

  const convertTime = (originalTime) => {
    const timeSlice = originalTime.split("-");
    return `${timeSlice[0]}-${timeSlice[1]}-${timeSlice[2]} ${timeSlice[3]}:${timeSlice[4]}:${timeSlice[5]}`;
  };

  /**
   * 拒絕身份驗證
   */
  const rejectVerification = async (avatarhash) => {
    setLoading(true);

    const response = await fetch(api.admin.nationalId, {
      method: "DELETE",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ type: "national-id", avatarhash, rejectReason }),
    }); 

    if (response.status === 200) {
      await showDialog("已退回申請並寄出通知 email。");
    } else {
      await showDialog("退回申請失敗，可能因為使用者的 email 無效。");
    }

    // 直接將資料改成未通過，不必再次打 API
    const ids_ = [...ids];
    const index = ids_.findIndex((id) => id.avatarhash === avatarhash);
    ids_[index].national_id_status = 2;
    setIds(ids_);
    setShowRejectModal(false);
    setRejectReason("");
    setLoading(false);
  };

  /**
   * 允許驗證通過
   */
  const approveVerification = async (avatarhash) => {
    if (window.confirm("確認通過申請？")) {
      setLoading(true);

      const response = await fetch(api.admin.nationalId, {
        method: "PATCH",
        headers: {
          Authorization: getCookie("session_key"),
        },
        body: JSON.stringify({ type: "national-id", avatarhash }),
      });

      if (response.status === 200) {
        await showDialog("已審核通過");

        // 直接將資料改成已審核，不必再次打 API
        const ids_ = [...ids];
        const index = ids_.findIndex((id) => id.avatarhash === avatarhash);
        ids_[index].national_id_status = 1;
        setIds(ids_);
      } else {
        await showDialog("審核失敗");
      }

      setLoading(false);
    }
  };

  /**
   * 按下「退回」按鈕
   */
  const clickRejectVerification = (avatarhash) => {
    setRejectingAvatarHash(avatarhash);
    setShowRejectModal(true);
  };

  useEffect(() => {
    fetchIds();
  }, [props.trigger]);

  return (
    <>
      {loading && <Loading />}
      {ids.length ? (
        <>
          <Modal
            show={showRejectModal}
            onHide={() => setShowRejectModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>請輸入退回原因</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                rows="5"
                placeholder="請輸入退回原因"
                autoFocus
                onChange={(e) => setRejectReason(e.target.value)}
              ></textarea>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-danger"
                onClick={() => {
                  rejectVerification(rejectingAvatarHash);
                }}
              >
                送出
              </button>
            </Modal.Footer>
          </Modal>
          <Table
            style={{ width: "80%", marginLeft: "10%" }}
            data-bs-theme="dark"
            className="mb-0"
          >
            <thead>
              <tr>
                <th className="mid-lavender-background">姓名</th>
                <th className="mid-lavender-background">身分證字號</th>
                <th className="mid-lavender-background">身分證照片</th>
                <th className="mid-lavender-background">狀態</th>
                <th className="mid-lavender-background">操作</th>
              </tr>
            </thead>
            <tbody>
              {ids.map((account, id) => (
                <tr key={id}>
                  <td className="dark-lavender-background">
                    {account.last_name}
                    {account.first_name}
                  </td>
                  <td className="dark-lavender-background">
                    {account.national_id}
                  </td>
                  <td className="dark-lavender-background">
                    <img
                      src={account.url}
                      alt="身分證照片"
                      style={{ width: "500px" }}
                    />
                  </td>
                  <td className="dark-lavender-background">
                    {account.national_id_status === 1
                      ? "已通過"
                      : account.national_id_status === 2
                      ? "未通過"
                      : "待審核"}
                  </td>
                  <td className="dark-lavender-background">
                    <button
                      className="btn btn-success me-3"
                      onClick={() => approveVerification(account.avatarhash)}
                      disabled={account.national_id_status !== 0}
                    >
                      通過
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        clickRejectVerification(account.avatarhash)
                      }
                      disabled={account.national_id_status !== 0}
                    >
                      退回
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <h3 className="mt-5">尚無資料</h3>
      )}
    </>
  );
};

export default IdVerification;
