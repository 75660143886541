import { useEffect, useState } from "react";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/user/user";
import { imagePrefix } from "../../../util/imagePrefix";
import { FaArrowRight } from "react-icons/fa";
import Loading from "../../../component/Loading";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../util/getCookie";
import FloatVisualizer from "../../../component/FloatVisualizer";
import { TbTrashXFilled } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import { api } from "../../../config/api";
import PurchaseModal from "../../../component/PurchaseModal";
import { addBaseTagToHTML, openHtml } from "../../../util/paymentHtml";
import showDialog from "../../../component/Dialog/showDialog";

const CartDetails = () => {
  // 購物車商品列表
  const [cartSkins, setCartSkins] = useState([]);

  // 載入中
  const [loading, setLoading] = useState(false);

  // 是否全選
  const [selectAll, setSelectAll] = useState(true);

  // 選擇的商品數量
  const [selectedSkinsNumber, setSelectedSkinsNumber] = useState(0);

  // 是否開啟填寫付款方式的視窗
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  // 購買商品的總價
  const [totalPrice, setTotalPrice] = useState(0);

  // 付款方式
  const [purchaseMethod, setPurchaseMethod] = useState("");

  // 使用者資訊
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      // The user has not logged in.
      navigate("/");
      return;
    }

    fetchCartSkins();
  }, []);

  useEffect(() => {
    setSelectedSkinsNumber(cartSkins.filter((skin) => skin.selected).length);
    setTotalPrice(
      cartSkins
        .filter((skin) => skin.selected)
        .reduce((acc, skin) => acc + parseFloat(skin.market_price), 0)
    );
  }, [cartSkins]);

  /**
   * 取得購物車商品列表
   */
  const fetchCartSkins = async () => {
    setLoading(true);

    const skins = await fetch(api.cart, {
      headers: {
        Authorization: getCookie("session_key"),
      },
    }).then((res) => res.json());

    skins.forEach((skin) => {
      skin.selected = true;
    });

    console.log(skins);

    // 僅顯示可購買的購物車商品
    setCartSkins(
      skins.filter((skin) => skin.active && skin.market_status_code === 0)
    );
    setLoading(false);
  };

  /**
   * 點擊商品後，將商品設為已選取或取消選取
   */
  const handleClickRow = (skin) => {
    cartSkins.forEach((cartSkin) => {
      if (cartSkin.id === skin.id) {
        cartSkin.selected = !cartSkin.selected;
      }
    });
    setCartSkins([...cartSkins]);
  };

  /**
   * 刪除購物車的物品
   */
  const handleDeleteSkin = async (id) => {
    setLoading(true);

    await fetch(api.cart, {
      method: "DELETE",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ id }),
    });

    // 在本地端移除商品，而非再向後端抓一次資料
    const newCartSkins = cartSkins.filter((skin) => skin.id !== id);
    setCartSkins(newCartSkins);
    setLoading(false);
  };

  /**
   * 購買所選商品
   */
  const handlePurchaseSkins = async () => {
    setLoading(true);

    const assetids = cartSkins
      .filter((skin) => skin.selected)
      .map((skin) => skin.assetid);

    // 檢查是否可購買此物品，bag_items_market_info.market_status_code 是否為 0，是的話才能購買，
    // 並且將 code 改成 2 表示正在等候付款，其他人無法購買。
    const canPurchaseAllItems = await fetch(api.purchase.validate, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ assetids }),
    }).then((res) => res.json());

    if (!canPurchaseAllItems) {
      await showDialog("不好意思，部分選擇的商品已不可購買，請重新選擇");
      setShowPurchaseModal(false);
      await fetchCartSkins();
      return;
    }

    const selectedItems = cartSkins.filter((item) => item.selected);

    // FunPoint 要求以 # 連接多個物品名稱
    const itemName = selectedItems.map((item) => item.item_name).join("#");

    const body = {
      purchaseMethod,
      price: totalPrice,
      itemName,
      items: selectedItems,
      clientBackUrl: window.location.origin + "/orders",
    };

    const purchaseHTML = await fetch(api.purchase.payment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());

    const baseHref = "https://payment-stage.funpoint.com.tw";
    const modifiedHTML = addBaseTagToHTML(purchaseHTML.html, baseHref);
    openHtml(modifiedHTML);
    setLoading(false);
    setShowPurchaseModal(false);

    // 跳轉至訂單紀錄頁面
    navigate("/orders");
  };

  /**
   * 檢查已選的商品狀態是否都可購買
   */
  const checkCanPurchase = () => {
    return cartSkins
      .filter((item) => item.selected)
      .every(
        (item) =>
          item.tradable_status_code === 1 || item.tradable_status_code === 2
      );
  };

  const handleClickCheckout = async () => {
    // Check whether the user's phone number has been verified
    if (!user.phone_verified) {
      await showDialog("請先至帳戶設定完成手機號碼驗證", true, "/setting");
      return;
    }

    setShowPurchaseModal(true);
  }

  return (
    <>
      {loading && <Loading />}
      {showPurchaseModal && (
        <PurchaseModal
          skins={cartSkins}
          totalPrice={totalPrice}
          purchaseMethod={purchaseMethod}
          setPurchaseMethod={setPurchaseMethod}
          handlePurchaseSkins={handlePurchaseSkins}
          setShowPurchaseModal={setShowPurchaseModal}
        />
      )}
      {cartSkins.length > 0 ? (
        <div className="cart-details-container ms-auto me-auto">
          <Row className="mb-2 ms-auto me-auto">
            <Col xs={8}>
              <h3>商品列表</h3>
            </Col>
          </Row>
          <Table
            style={{ textAlign: "center" }}
            className="ms-auto me-auto mt-3 mb-3"
            data-bs-theme="dark"
          >
            <thead>
              <tr>
                <th className="mid-lavender-background">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      setSelectAll(!selectAll);
                      cartSkins.forEach((item) => {
                        item.selected = !selectAll;
                      });
                      setCartSkins([...cartSkins]);
                    }}
                  >
                    <FaCheck />
                  </Button>
                </th>
                <th className="mid-lavender-background">圖片</th>
                <th className="mid-lavender-background">商品名稱</th>
                <th className="mid-lavender-background">磨損度</th>
                <th className="mid-lavender-background">價格</th>
                <th className="mid-lavender-background">刪除</th>
              </tr>
            </thead>
            <tbody>
              {cartSkins.map((skin) => (
                <tr key={skin.id} onClick={() => handleClickRow(skin)}>
                  <td className="dark-lavender-background">
                    <Form.Check
                      checked={skin.selected}
                      onChange={(e) => {
                        skin.selected = e.target.checked;
                        if (!e.target.checked) {
                          setSelectAll(false);
                        }
                        setCartSkins([...cartSkins]);
                      }}
                    />
                  </td>
                  <td className="dark-lavender-background">
                    <img
                      src={imagePrefix + skin.icon_url}
                      alt="skin"
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td className="dark-lavender-background">{skin.item_name}</td>
                  <td
                    className="text-start dark-lavender-background"
                    style={{ fontSize: "14px" }}
                  >
                    磨損: {skin.float_value}
                    <FloatVisualizer float={skin.float_value} />
                  </td>
                  <td className="dark-lavender-background">
                    NTD {parseFloat(skin.market_price)}
                  </td>
                  <td className="dark-lavender-background">
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDeleteSkin(skin.id)}
                    >
                      <TbTrashXFilled size={20} className="mb-1" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
            className="pb-5"
          >
            <Button
              variant="warning"
              className="me-2"
              style={{ display: "flex", alignItems: "center" }}
              disabled={!selectedSkinsNumber || !checkCanPurchase()}
              onClick={handleClickCheckout}
            >
              結帳 ({selectedSkinsNumber}){" "}
              <FaArrowRight size={15} className="mt-1 ms-2" />
            </Button>
          </div>
        </div>
      ) : (
        <h3
          className="ms-auto me-auto mt-5"
          style={{ width: "100%", textAlign: "center", color: "#ccc" }}
        >
          購物車空著，馬上回首頁逛逛吧！
        </h3>
      )}
    </>
  );
};
export default CartDetails;
