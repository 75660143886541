import {
  Row,
  Col,
  OverlayTrigger,
  Form,
  Tooltip,
  Button,
} from "react-bootstrap";
import "./style.css";

/**
 * 提供使用者選擇付款方式的彈窗
 */
const PurchaseModal = ({
  skins,
  totalPrice,
  purchaseMethod,
  setPurchaseMethod,
  handlePurchaseSkins,
  setShowPurchaseModal,
}) => {
  /**
   * 渲染「說明為何付款方式無法選取」的 Tooltip
   */
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.name}
    </Tooltip>
  );

  return (
    <div className="purchase-modal-container">
      <div className="purchase-modal">
        <h4>商品清單</h4>
        <Row style={{ width: "100%" }}>
          <Col xs={8} style={{ textAlign: "left", fontWeight: "bold" }}>
            名稱
          </Col>
          <Col xs={4} style={{ textAlign: "left", fontWeight: "bold" }}>
            價格
          </Col>
        </Row>
        {skins
          .filter((item) => item.selected)
          .map((item) => (
            <Row key={item.id} style={{ width: "100%" }}>
              <Col xs={8} style={{ textAlign: "left" }}>
                {item.item_name}
              </Col>
              <Col xs={4} style={{ textAlign: "left" }}>
                NTD {+item.market_price}
              </Col>
            </Row>
          ))}
        <p className="mt-3" style={{ fontWeight: "bold" }}>
          總價：NTD {totalPrice}
        </p>
        <h4>請選擇付款方式</h4>
        <div className="radios">
          {+totalPrice < 36 || +totalPrice > 20000 ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip({
                name: "便利商店僅限購買價格介於 36~20000 的物品",
              })}
            >
              <div>
                <Form.Check
                  type={"radio"}
                  id={`default-radio-1`}
                  label={`便利商店`}
                  className="radio"
                  onChange={() => setPurchaseMethod("convenience_store")}
                  checked={purchaseMethod === "convenience_store"}
                  disabled
                />
              </div>
            </OverlayTrigger>
          ) : (
            <Form.Check
              type={"radio"}
              id={`default-radio-1`}
              label={`便利商店`}
              className="radio"
              onChange={() => setPurchaseMethod("convenience_store")}
              checked={purchaseMethod === "convenience_store"}
            />
          )}

          {+totalPrice < 5 || +totalPrice > 199999 ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip({
                name: "信用卡僅限購買價格介於 5~199999 的物品",
              })}
            >
              <div>
                <Form.Check
                  type={"radio"}
                  id={`default-radio-2`}
                  label={`信用卡`}
                  className="radio"
                  onChange={() => setPurchaseMethod("credit_card")}
                  checked={purchaseMethod === "credit_card"}
                  disabled
                />
              </div>
            </OverlayTrigger>
          ) : (
            <Form.Check
              type={"radio"}
              id={`default-radio-2`}
              label={`信用卡`}
              className="radio"
              onChange={() => setPurchaseMethod("credit_card")}
              checked={purchaseMethod === "credit_card"}
            />
          )}

          {+totalPrice < 11 || +totalPrice > 49999 ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip({
                name: "ATM 轉帳僅限購買價格介於 11~49999 的物品",
              })}
            >
              <div>
                <Form.Check
                  type={"radio"}
                  id={`default-radio-3`}
                  label={`ATM 轉帳`}
                  className="radio"
                  onChange={() => setPurchaseMethod("ATM")}
                  checked={purchaseMethod === "ATM"}
                  disabled
                />
              </div>
            </OverlayTrigger>
          ) : (
            <Form.Check
              type={"radio"}
              id={`default-radio-3`}
              label={`ATM 轉帳`}
              className="radio"
              onChange={() => setPurchaseMethod("ATM")}
              checked={purchaseMethod === "ATM"}
            />
          )}
        </div>
        <div className="buttons">
          <Button
            className="purchase-button"
            onClick={handlePurchaseSkins}
            variant="warning"
            disabled={!purchaseMethod}
          >
            確認購買
          </Button>
          <Button
            onClick={() => {
              setShowPurchaseModal(false);
              setPurchaseMethod("");
            }}
          >
            取消
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseModal;
