import { useEffect, useState } from "react";
import { Table, Row, Col, Form, Card, Button } from "react-bootstrap";
import { FiExternalLink } from "react-icons/fi";
import { imagePrefix } from "../../../util/imagePrefix";
import { getCookie } from "../../../util/getCookie";
import Loading from "../../../component/Loading";
import { api } from "../../../config/api";
import FloatVisualizer from "../../../component/FloatVisualizer";
import { FaCheck } from "react-icons/fa";
import showDialog from "../../../component/Dialog/showDialog";

const MySaleRecord = () => {
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [skins, setSkins] = useState([]);

  /**
   * 取得使用者出售中物品
   */
  const fetchOffer = async () => {
    setLoading(true);

    const response = await fetch(api.skins.user, {
      headers: new Headers({
        Authorization: getCookie("session_key"),
      }),
    }).then((res) => res.json());

    const skins_ = response.skins;
    const offer = skins_.filter((ele) => ele.market_status_code === 0);

    setSkins(offer);
    setLoading(false);
  };

  useEffect(() => {
    let price_sum = 0;
    skins.forEach((ele) => {
      if (ele.selected) price_sum += parseFloat(ele.market_price);
    });
    setTotalPrice(price_sum);
  }, [skins]);

  useEffect(() => {
    fetchOffer();
  }, []);

  const handleSelect = (id) => {
    setSkins((prev) => {
      const temp = prev.slice();
      temp[id].selected = !temp[id].selected;
      return temp;
    });
  };

  const handlePriceChange = (id, price) => {
    setSkins((prev) => {
      const temp = prev.slice();
      temp[id].market_price = price;
      return temp;
    });
  };

  /**
   * 更新一件物品的價格
   */
  const handleUpdateSalePrice = async (assetid, price) => {
    await fetch(
      api.skins.marketInfo,
      {
        method: "PATCH",
        body: JSON.stringify({
          assetid,
          new_price: price,
          operation: "update-price",
        }),
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: getCookie("session_key"),
        }),
      }
    );
  };

  /**
   * 下架物品
   */
  const handleCancelSale = async (assetid) => {
    await fetch(
      api.skins.marketInfo,
      {
        method: "PATCH",
        body: JSON.stringify({
          assetid,
          operation: "off-sale",
        }),
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: getCookie("session_key"),
        }),
      }
    );
  };

  /**
   * 下架選取的物品
   */
  const handleClickCancelSale = async () => {
    setLoading(true);
    const skins_ = skins.filter((ele) => ele.selected);

    for (const skin of skins_) {
      await handleCancelSale(skin.assetid);
    }

    setLoading(false);
    await showDialog("下架成功！");
    await fetchOffer();
  };

  /**
   * 更改物品價格
   */
  const handleClickPriceChange = async () => {
    setLoading(true);
    const skins_ = skins.filter((ele) => ele.selected);

    for (const skin of skins_) {
      await handleUpdateSalePrice(skin.assetid, skin.market_price);
    }

    setLoading(false);
    await showDialog("更新成功！");
    await fetchOffer();
  };

  /**
   * If all skins are selected, deselect all skins. Otherwise, select all skins.
   */
  const selectAll = () => {
    const skins_ = skins.slice();
    const allSelected = skins_.every((ele) => ele.selected);
    if (allSelected) {
      skins_.forEach((ele) => {
        ele.selected = false;
      });
    } else {
      skins_.forEach((ele) => {
        ele.selected = true;
      });
    }
    setSkins(skins_);
  };

  return (
    <>
      {loading && <Loading />}
      {skins.length === 0 ? (
        <div className="ms-auto me-auto mt-5 mb-5">
          <h3 style={{ color: "#ccc" }}>您尚未出售任何物品。</h3>
        </div>
      ) : (
        <Row
          className="mb-5 mt-5 ms-auto me-auto"
          style={{ width: "80%", minWidth: "600px" }}
        >
          <Col xs="8">
            <Table data-bs-theme="dark">
              <thead>
                <tr>
                  <th className="mid-lavender-background">
                    <Button size="sm" onClick={selectAll} variant="outline-warning">
                      <FaCheck />
                    </Button>
                  </th>
                  <th className="mid-lavender-background">圖片</th>
                  <th className="mid-lavender-background">物品</th>
                  <th className="mid-lavender-background">價格</th>
                </tr>
              </thead>
              <tbody>
                {skins.map((skin, id) => (
                  <tr key={id}>
                    <td
                      className="dark-lavender-background"
                      onClick={() => handleSelect(id)}
                    >
                      <Form.Check checked={skin.selected} />
                    </td>
                    <td
                      className="dark-lavender-background"
                      onClick={() => handleSelect(id)}
                    >
                      <img
                        src={imagePrefix + skin.icon_url}
                        style={{ width: "100px" }}
                        className="me-2"
                      />
                      <a
                        href={skin.inspect_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FiExternalLink style={{ color: "gray" }} />
                      </a>
                    </td>
                    <td
                      style={{ textAlign: "left" }}
                      className="dark-lavender-background"
                      onClick={() => handleSelect(id)}
                    >
                      {skin.item_name}
                      <br />
                      <span style={{ color: "gray", fontSize: "12px" }}>
                        {skin.float_value >= 0 && `磨損度: ${skin.float_value}`}
                        {skin.float_value < 0 && "磨損度: 無法取得"}
                      </span>
                      {skin.float_value >= 0 && (
                        <FloatVisualizer float={skin.float_value} />
                      )}
                    </td>
                    <td className="dark-lavender-background">
                      <Form.Control
                        disabled={!skin.selected}
                        maxLength={17}
                        defaultValue={skin.market_price}
                        onChange={(e) => handlePriceChange(id, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Card
              className="mt-2 dark-lavender-background"
              style={{ border: "none", color: "#ccc" }}
            >
              <Card.Text className="mt-4 mb-1">
                <span className="float-start ms-5">銷售所得</span>
                <span className="float-end me-5">{totalPrice.toFixed(2)}</span>
                <br />
                <span className="float-start ms-5 mt-2">
                  -&nbsp;&nbsp;&nbsp;手續費(5%)
                </span>
                <span className="float-end me-5 mt-2">
                  {(totalPrice * 0.05).toFixed(2)}
                </span>
              </Card.Text>
              <hr style={{ width: "80%" }} className="ms-auto me-auto mt-2" />
              <Card.Text className="mb-3">
                <span className="float-start ms-5">實際所得</span>
                <span className="float-end me-5">
                  {(totalPrice * 0.95).toFixed(2)}
                </span>
              </Card.Text>
            </Card>
            <Button
              className="float-end me-5 ms-3"
              onClick={handleClickCancelSale}
              variant="outline-danger"
            >
              下架
            </Button>
            <Button
              className="float-end"
              variant="outline-warning"
              onClick={handleClickPriceChange}
            >
              更改價格
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MySaleRecord;
