import { Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Select from "./Select/Select";
import { AiOutlineSearch } from "react-icons/ai";

const Filter = (props) => {
  const [quality, setQuality] = useState("");

  const [qualities, setQualities] = useState({
    所有類別: "",
    普通: "65",
    紀念品: "66",
    "StatTrak™": "67",
    "★": "68",
    "★ StatTrak™": "69",
  });

  const [rarity, setRarity] = useState("");

  const [rarities, setRarities] = useState({
    所有品質: "",
    消費級: "76",
    軍規級: "77",
    工業級: "78",
    受管制: "79",
    機密: "80",
    隱蔽: "81",
    初級: "82",
    傑出: "83",
    優秀: "84",
    不朽: "85",
    超絕: "86",
    大師: "87",
    高級: "88",
    神話: "89",
    特製: "90",
    違禁品: "91",
  });

  const [exterior, setExterior] = useState("");

  const [exteriors, setExteriors] = useState({
    所有外觀: "",
    全新出廠: "75",
    輕微磨損: "74",
    戰場實測: "73",
    重度磨損: "72",
    戰痕累累: "71",
  });

  const [order, setOrder] = useState("");

  const [orders, setOrders] = useState({
    低價優先: "low-price",
    高價優先: "high-price",
    低磨損優先: "low-worn",
    高磨損優先: "high-worn",
  });

  const [lowerPrice, setLowerPrice] = useState("");

  const [upperPrice, setUpperPrice] = useState("");

  // Whether the options of selects can be shown. If the user clicks outside the div, the options will be hidden.
  const [canShowOptions, setCanShowOptions] = useState(false);

  const filterBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterBoxRef.current &&
        !filterBoxRef.current.contains(event.target)
      ) {
        // If the user clicks outside the filter, hide the options.
        setCanShowOptions(false);
      } else {
        // If the user clicks inside the filter, enable the selects to show options.
        setCanShowOptions(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    props.getBagItems({ quality, rarity, exterior, order });
  }, [quality, rarity, exterior, order]);

  useEffect(() => {
    if (!lowerPrice && !upperPrice) {
      props.filterByPrice();
    }
  }, [lowerPrice, upperPrice]);

  useEffect(() => {
    if (props.defaultWornStatus) {
      setExterior(props.defaultWornStatus.toString());
    }
  }, [props.defaultWornStatus]);

  return (
    <>
      <div
        ref={filterBoxRef}
        style={{
          width: "fit-content",
          margin: "auto",
          gap: "10px",
        }}
        className="mt-4 d-flex align-items-center justify-content-center"
      >
        <Select
          title="類別"
          options={qualities}
          setValue={setQuality}
          canShowOptions={canShowOptions}
        />
        <Select
          title="品質"
          options={rarities}
          setValue={setRarity}
          canShowOptions={canShowOptions}
        />
        <Select
          title="外觀"
          options={exteriors}
          setValue={setExterior}
          canShowOptions={canShowOptions}
          defaultExterior={exterior}
        />
        <Select
          title="排序"
          options={orders}
          setValue={setOrder}
          canShowOptions={canShowOptions}
        />
      </div>
      <div className="ms-auto me-auto mt-3 price-container">
        <Form.Control
          type="number"
          placeholder="最低價"
          onChange={(e) => setLowerPrice(e.target.value)}
          value={lowerPrice}
          data-bs-theme="dark"
          className="price-input me-2"
        />

        <p style={{ color: "#ccc", margin: "0" }}>-</p>

        <Form.Control
          type="number"
          placeholder="最高價"
          onChange={(e) => setUpperPrice(e.target.value)}
          value={upperPrice}
          data-bs-theme="dark"
          className="price-input ms-2 me-2"
        />

        <div className="search-button price-search-button">
          <AiOutlineSearch
            size={20}
            color="#ccc"
            onClick={() => props.filterByPrice(lowerPrice, upperPrice)}
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
