export const badgeColorMap = {
  75: 'success',
  74: 'primary',
  73: 'info',
  72: 'warning',
  71: 'danger'
};

export const badgeTextColorMap = {
  75: 'light',
  74: 'light',
  73: 'dark',
  72: 'dark',
  71: 'light'
};

export const wornStatusTranslationMap = {
  75: '全新出廠',
  74: '輕微磨損',
  73: '戰場實測',
  72: '重度磨損',
  71: '戰痕累累'
};

export const wornStatusTranslationToEnglishMap = {
  '全新出廠': 'Factory New',
  '輕微磨損': 'Minimal Wear',
  '戰場實測': 'Field-Tested',
  '重度磨損': 'Well-Worn',
  '戰痕累累': 'Battle-Scarred'
}

export const withdrawStatusTranslationMap = {
  'COOLING_DOWN': '冷卻中',
  'PENDING_RECV': '等待中',
  'TRADABLE': '可交易',
  'SENT': '已領回'
}

export const recordTypeCodeMap = {
  'DEPOSIT_REQUEST': '存入中',
  'DEPOSIT_SUCCESS': '存入成功',
  'DEPOSIT_FAIL': '存入失敗',
  'WITHDRAW_REQUEST': '領回中',
  'WITHDRAW_SUCCESS': '領回成功',
  'WITHDRAW_FAIL': '領回失敗',
}

export const typeTranslationMap = {
  'pistols': '手槍',
  'mid_tier': '中階',
  'submachine_gun': '衝鋒槍',
  'machine_gun': '機關槍',
  'shotgun': '霰彈槍',
  'rifles': '步槍',
  'knives': '近戰武器',
  'gloves': '手套',
  'agents': '幹員',
  'stickers': '貼紙',
  'others': '其他',
}

export const tradableStatusCodeMap = {
  0: '待確認',
  1: '冷卻中',
  2: '可交易',
}

export const weaponNames = [
  {
    "name_id": "1",
    "name": "P250",
    "type_id": "1"
  },
  {
    "name_id": "2",
    "name": "Glock-18",
    "type_id": "1"
  },
  {
    "name_id": "3",
    "name": "Tec-9",
    "type_id": "1"
  },
  {
    "name_id": "4",
    "name": "Desert Eagle",
    "type_id": "1"
  },
  {
    "name_id": "5",
    "name": "Five-SeveN",
    "type_id": "1"
  },
  {
    "name_id": "6",
    "name": "CZ75-Auto",
    "type_id": "1"
  },
  {
    "name_id": "7",
    "name": "USP-S",
    "type_id": "1"
  },
  {
    "name_id": "8",
    "name": "Dual Berettas",
    "type_id": "1"
  },
  {
    "name_id": "9",
    "name": "P2000",
    "type_id": "1"
  },
  {
    "name_id": "10",
    "name": "R8 Revolver",
    "type_id": "1"
  },
  {
    "name_id": "11",
    "name": "MAC-10",
    "type_id": "2"
  },
  {
    "name_id": "12",
    "name": "MP5-SD",
    "type_id": "2"
  },
  {
    "name_id": "13",
    "name": "MP7",
    "type_id": "2"
  },
  {
    "name_id": "14",
    "name": "MP9",
    "type_id": "2"
  },
  {
    "name_id": "15",
    "name": "PP-Bizon",
    "type_id": "2"
  },
  {
    "name_id": "16",
    "name": "P90",
    "type_id": "2"
  },
  {
    "name_id": "17",
    "name": "UMP-45",
    "type_id": "2"
  },
  {
    "name_id": "18",
    "name": "M249",
    "type_id": "3"
  },
  {
    "name_id": "19",
    "name": "Negev",
    "type_id": "3"
  },
  {
    "name_id": "20",
    "name": "XM1014",
    "type_id": "4"
  },
  {
    "name_id": "21",
    "name": "Nova",
    "type_id": "4"
  },
  {
    "name_id": "22",
    "name": "Sawed-Off",
    "type_id": "4"
  },
  {
    "name_id": "23",
    "name": "MAG-7",
    "type_id": "4"
  },
  {
    "name_id": "24",
    "name": "M4A1-S",
    "type_id": "5"
  },
  {
    "name_id": "25",
    "name": "AK-47",
    "type_id": "5"
  },
  {
    "name_id": "26",
    "name": "AUG",
    "type_id": "5"
  },
  {
    "name_id": "27",
    "name": "AWP",
    "type_id": "5"
  },
  {
    "name_id": "28",
    "name": "FAMAS",
    "type_id": "5"
  },
  {
    "name_id": "29",
    "name": "G3SG1",
    "type_id": "5"
  },
  {
    "name_id": "30",
    "name": "Galil AR",
    "type_id": "5"
  },
  {
    "name_id": "31",
    "name": "M4A4",
    "type_id": "5"
  },
  {
    "name_id": "32",
    "name": "SCAR-20",
    "type_id": "5"
  },
  {
    "name_id": "33",
    "name": "SG 553",
    "type_id": "5"
  },
  {
    "name_id": "34",
    "name": "SSG 08",
    "type_id": "5"
  },
  {
    "name_id": "35",
    "name": "熊刀",
    "type_id": "6"
  },
  {
    "name_id": "36",
    "name": "Nomad Knife",
    "type_id": "6"
  },
  {
    "name_id": "37",
    "name": "Skeleton Knife",
    "type_id": "6"
  },
  {
    "name_id": "38",
    "name": "Survival Knife",
    "type_id": "6"
  },
  {
    "name_id": "39",
    "name": "Paracord Knife",
    "type_id": "6"
  },
  {
    "name_id": "40",
    "name": "經典軍刀",
    "type_id": "6"
  },
  {
    "name_id": "41",
    "name": "刺刀",
    "type_id": "6"
  },
  {
    "name_id": "42",
    "name": "布伊刀",
    "type_id": "6"
  },
  {
    "name_id": "43",
    "name": "蝴蝶刀",
    "type_id": "6"
  },
  {
    "name_id": "44",
    "name": "彎刃匕首",
    "type_id": "6"
  },
  {
    "name_id": "45",
    "name": "折疊式軍刀",
    "type_id": "6"
  },
  {
    "name_id": "46",
    "name": "穿腸刀",
    "type_id": "6"
  },
  {
    "name_id": "47",
    "name": "獵刀",
    "type_id": "6"
  },
  {
    "name_id": "48",
    "name": "爪刀",
    "type_id": "6"
  },
  {
    "name_id": "49",
    "name": "M9 刺刀",
    "type_id": "6"
  },
  {
    "name_id": "50",
    "name": "西班牙折刀",
    "type_id": "6"
  },
  {
    "name_id": "51",
    "name": "暗影匕首",
    "type_id": "6"
  },
  {
    "name_id": "52",
    "name": "Stiletto Knife",
    "type_id": "6"
  },
  {
    "name_id": "53",
    "name": "Talon Knife",
    "type_id": "6"
  },
  {
    "name_id": "54",
    "name": "駕駛",
    "type_id": "7"
  },
  {
    "name_id": "55",
    "name": "手綁帶",
    "type_id": "7"
  },
  {
    "name_id": "56",
    "name": "機車手套",
    "type_id": "7"
  },
  {
    "name_id": "57",
    "name": "技術士手套",
    "type_id": "7"
  },
  {
    "name_id": "58",
    "name": "運動員手套",
    "type_id": "7"
  },
  {
    "name_id": "59",
    "name": "九頭蛇手套",
    "type_id": "7"
  },
  {
    "name_id": "60",
    "name": "Broken Fang Gloves",
    "type_id": "7"
  },
  {
    "name_id": "61",
    "name": "獵犬手套",
    "type_id": "7"
  },
  {
    "name_id": "62",
    "name": "恐怖份子",
    "type_id": "8"
  },
  {
    "name_id": "63",
    "name": "反恐小組",
    "type_id": "8"
  },
  {
    "name_id": "64",
    "name": "普通",
    "type_id": "9"
  },
  {
    "name_id": "65",
    "name": "彩光",
    "type_id": "9"
  },
  {
    "name_id": "66",
    "name": "閃亮",
    "type_id": "9"
  },
  {
    "name_id": "67",
    "name": "黃金",
    "type_id": "9"
  },
  {
    "name_id": "68",
    "name": "Glitter",
    "type_id": "9"
  }
]

export const ExteriorCodeMap = {
  70: '尚未塗鴉',
  71: '戰痕累累',
  72: '重度磨損',
  73: '戰場實測',
  74: '輕微磨損',
  75: '全新出廠',
}

/**
 * 磨損度對應 ID
 */
export const ExteriorEnglishNameToId = {
  "Not Painted": "70",
  "Battle-Scarred": "71",
  "Well-Worn": "72",
  "Field-Tested": "73",
  "Minimal Wear": "74",
  "Factory New": "75",
};