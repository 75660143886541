import React from "react";
import MainNavbar from "../../component/MainNavbar";
import "../style.css";

const FAQ = () => {
  return (
    <div className="basic-page">
      <MainNavbar />
      <div
        className="text-start mb-5"
        style={{ marginLeft: "20%", width: "60%", minWidth: "600px" }}
      >
        <h1 className="mt-5 mb-5">常見問題</h1>
      </div>
    </div>
  );
};

export default FAQ;
