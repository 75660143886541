import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DisplaySkinItem from "./DisplaySkinItem";
import { useSelector } from "react-redux";
import Filter from "../../../component/Filter";
import Loading from "../../../component/Loading";
import {
  selectSearchKey,
  selectSearchTrigger,
} from "../../../features/searchKey/searchKey";
import { api } from "../../../config/api";
import showDialog from "../../../component/Dialog/showDialog";

const DisplaySkin = () => {
  // Store the data of skins
  const [skins, setSkins] = useState([]);

  // Store all skins for backup when filtering by price
  const [allSkins, setAllSkins] = useState([]);

  // Whether the data is loading
  const [loading, setLoading] = useState(true);

  // The key word for searching
  const searchKey = useSelector(selectSearchKey);

  // The trigger for searching
  const trigger = useSelector(selectSearchTrigger);

  /**
   * Get bag items with filters.
   */
  const getBagItems = async (filters = {}) => {
    setLoading(true);

    const allItems = await fetch(
      `${api.skins.all}?searchKey=${encodeURIComponent(searchKey)}&rarityId=${
        filters.rarity || ""
      }&qualityId=${filters.quality || ""}&exteriorId=${filters.exterior || ""}`
    ).then((res) => res.json());

    const { skins: skins_ } = allItems;

    // Only display skins with market_status_code = 0 which means it can be sold.
    const sellingSkins = skins_.filter((skin) => skin.market_status_code === 0);

    if (filters.order) {
      if (filters.order.includes("price")) {
        sellingSkins.sort((a, b) => {
          if (filters.order === "low-price") {
            return +a.market_price - +b.market_price;
          } else {
            return +b.market_price - +a.market_price;
          }
        });
      }

      if (filters.order.includes("worn")) {
        sellingSkins.sort((a, b) => {
          if (filters.order === "low-worn") {
            return +a.float_value - +b.float_value;
          } else {
            return +b.float_value - +a.float_value;
          }
        });
      }
    }

    setSkins(sellingSkins);
    setAllSkins(sellingSkins);
    setLoading(false);
  };

  /**
   * Filter the skins by price.
   */
  const filterByPrice = async (lowerPrice, upperPrice) => {
    const lowerPrice_ = parseFloat(lowerPrice || 0);
    const upperPrice_ = parseFloat(upperPrice || Infinity);

    if (isNaN(lowerPrice_) || isNaN(upperPrice_)) {
      await showDialog("請輸入有效的價格！");
      return;
    }

    if (lowerPrice_ > upperPrice_) {
      await showDialog("下限價格不能高於上限價格！");
      return;
    }

    const filteredSkins = allSkins.filter(
      (skin) =>
        parseFloat(skin.market_price) >= lowerPrice_ &&
        parseFloat(skin.market_price) <= upperPrice_
    );

    setSkins(filteredSkins);
  };

  useEffect(() => {
    getBagItems();
  }, [trigger]);

  return (
    <>
      {loading && <Loading />}
      <Filter getBagItems={getBagItems} filterByPrice={filterByPrice} />
      <Row style={{ width: "80%" }} className="ms-auto me-auto">
        {skins.map((skin, id) => (
          <Col key={id} xs={4} className="d-flex justify-content-center">
            <DisplaySkinItem skin={skin} />
          </Col>
        ))}
        {skins.length === 0 && (
          <h3 className="mt-5 mb-5" style={{ color: "#ccc" }}>
            尚無物品！
          </h3>
        )}
      </Row>
    </>
  );
};

export default DisplaySkin;
