import {
  Row,
  Col,
  Table,
  Form,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { imagePrefix } from "../../../util/imagePrefix";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/user/user";
import { FiExternalLink } from "react-icons/fi";
import { IoReload } from "react-icons/io5";
import {
  extractImageLinks,
  extractImageName,
} from "../../../util/getStickerLink";
import { getCookie } from "../../../util/getCookie";
import { selectInventory } from "../../../features/inventory/inventory";
import { useDispatch } from "react-redux";
import { updateInventory } from "../../../features/inventory/inventory";
import { updateAllInventory } from "../../../features/allInventory/allInventory";
import FloatVisualizer from "../../../component/FloatVisualizer";
import { api } from "../../../config/api";
import showDialog from "../../../component/Dialog/showDialog";
import "./style.css";

const MySteamInventory = (props) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const user = useSelector(selectUser);
  const [steamInventoryDescription, setSteamInventoryDescription] = useState(
    []
  );
  const [steamInventoryAssets, setSteamInventoryAssets] = useState([]);

  const dispatch = useDispatch();

  const inventory = useSelector(selectInventory);

  /**
   * Fetch the user's skins in TWSKINS database, and then fetch the steam inventory.
   * For every skin in steam inventory, if it is in TWSKINS database, set the "deposited" attribute to true.
   * @param forced whether to fetch the steam inventory again. If not, use the cached inventory.
   */
  const fetchSteamInventory = async (forced) => {
    const skinsInTwskins = await fetch(api.skins.user, {
      headers: new Headers({
        Authorization: getCookie("session_key"),
      }),
    }).then((res) => res.json());

    if (inventory.assets !== undefined && !forced) {
      const newDescriptions = inventory.descriptions.map((obj) => ({
        ...obj,
        selected: false,
        price: "",
      }));

      dispatch(updateAllInventory(inventory));

      for (let i = 0; i < newDescriptions.length; i++) {
        for (let j = 0; j < skinsInTwskins.skins.length; j++) {
          if (
            inventory.assets[i].assetid ===
              skinsInTwskins.skins[j].assetid.toString() &&
            skinsInTwskins.skins[j].market_status_code === 0
          ) {
            newDescriptions[i].deposited = true;
            newDescriptions[i].price = +skinsInTwskins.skins[j].market_price;
            break;
          }
          if (j === skinsInTwskins.skins.length - 1) {
            newDescriptions[i].deposited = false;
          }
        }
      }

      setSteamInventoryDescription(newDescriptions);
      setSteamInventoryAssets(inventory.assets);
      props.setLoading(false);
      return;
    }

    if (user.steam_id !== undefined) {
      let response = {};

      try {
        response = await fetch(api.skins.steamInventory, {
          headers: {
            Authorization: getCookie("session_key"),
          },
        }).then((res) => res.json());
      } catch (error) {
        console.log(error);
      }

      if (
        response.successful &&
        response.data.total_inventory_count > 0 &&
        response.data.descriptions.length > 0
      ) {
        let descriptions = response.data.descriptions;

        const inspectLinks = [];

        for (let i = 0; i < descriptions.length; ++i) {
          const actions = descriptions[i].actions;

          if (!actions) {
            inspectLinks.push("");
            continue;
          }

          const inspectLink = getFullInspectLink(
            actions[0].link,
            response.data.assets[i].assetid
          );
          inspectLinks.push(inspectLink);

          if (inspectLinks.length === 5 || i === descriptions.length - 1) {
            try {
              const floatResponse = await fetch(api.skins.float, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: getCookie("session_key"),
                },
                body: JSON.stringify({
                  inspectLinks,
                }),
              }).then((res) => res.json());

              for (let j = 0; j < floatResponse.floats.length; ++j) {
                descriptions[i - inspectLinks.length + 1 + j].float =
                  floatResponse.floats[j];
              }

              inspectLinks.splice(0, inspectLinks.length);
            } catch (error) {
              for (let j = 0; j < inspectLinks.length; ++j) {
                descriptions[i - inspectLinks.length + 1 + j].float = -1;
              }

              inspectLinks.splice(0, inspectLinks.length);
            }
          }
        }

        for (const description of descriptions) {
          if (description.descriptions.length < 7) {
            continue;
          }
          const stickers = description.descriptions[6]?.value;
          if (!stickers) continue;
          const imageLinks = extractImageLinks(stickers);
          const imageNames = extractImageName(stickers);
          let images = [];
          for (let i = 0; i < imageLinks.length; i++) {
            images.push({
              link: imageLinks[i],
              name: imageNames[i],
            });
          }
          description.stickers = images;
        }

        const newDescriptions = descriptions.map((obj) => ({
          ...obj,
          selected: false,
          price: "",
        }));

        for (let i = 0; i < newDescriptions.length; i++) {
          for (let j = 0; j < skinsInTwskins.skins.length; j++) {
            if (
              response.data.assets[i].assetid ===
                skinsInTwskins.skins[j].assetid.toString() &&
              skinsInTwskins.skins[j].market_status_code === 0
            ) {
              newDescriptions[i].deposited = true;
              newDescriptions[i].price = +skinsInTwskins.skins[j].market_price;
              break;
            }
            if (j === skinsInTwskins.skins.length - 1) {
              newDescriptions[i].deposited = false;
            }
          }
        }

        setSteamInventoryDescription(newDescriptions);
        setSteamInventoryAssets(response.data.assets);

        dispatch(
          updateInventory({
            descriptions: descriptions,
            assets: response.data.assets,
          })
        );
      } else {
        setSteamInventoryDescription([]);
        setSteamInventoryAssets([]);
      }
      props.setLoading(false);
    } else {
      setSteamInventoryDescription([]);
      setSteamInventoryAssets([]);
      props.setLoading(false);
    }
  };

  useEffect(() => {
    fetchSteamInventory(false);
  }, []);

  const handleSelect = (id) => {
    // If the item is already deposited, do not allow selection
    if (steamInventoryDescription[id].deposited) {
      return;
    }

    setSteamInventoryDescription((prev) => {
      const temp = prev.slice();
      temp[id].selected = !temp[id].selected;
      return temp;
    });
  };

  const handlePriceChange = (id, price) => {
    setSteamInventoryDescription((prev) => {
      const temp = prev.slice();
      temp[id].price = price;
      return temp;
    });
  };

  useEffect(() => {
    let total = 0;
    steamInventoryDescription.forEach((ele) => {
      if (ele.selected && ele.price.length > 0) {
        total += parseFloat(ele.price);
      }
    });
    setTotalPrice(total);
  }, [steamInventoryDescription]);

  /**
   * Get the full inspect link with actual assetid and owner_id (steam_id)
   * @param {*} link original inspect link
   * @param {*} id the index of the item in the inventory
   */
  const getFullInspectLink = (link, assetid) => {
    const steam_id = user.steam_id;
    const withSteamID = link.replace(/%owner_steamid%/g, steam_id);
    const withAll = withSteamID.replace(/%assetid%/g, assetid);
    return withAll;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.name}
    </Tooltip>
  );

  /**
   * Sell items in the inventory
   */
  const handleSell = async (e) => {
    e.preventDefault();

    // Check whether the user's national ID and phone number have been verified
    if (!user.phone_verified || user.national_id_status !== 1) {
      await showDialog(
        "請先至帳戶設定完成手機號碼和身分驗證",
        true,
        "/setting"
      );
      return;
    }

    const session_key = getCookie("session_key");

    // The skins to be stored
    const skins = [];

    for (let i = 0; i < steamInventoryDescription.length; ++i) {
      if (steamInventoryDescription[i].selected) {
        if (
          steamInventoryDescription[i].price.length === 0 ||
          !steamInventoryDescription[i].price.match(/^\d+(\.\d{1,2})?$/)
        ) {
          await showDialog("請輸入合理的價格");
          return;
        }
        skins.push({
          inspect_link: getFullInspectLink(
            steamInventoryDescription[i].actions[0].link,
            steamInventoryAssets[i].assetid
          ),
          ...steamInventoryAssets[i],
          ...steamInventoryDescription[i],
        });
      }
    }

    props.setLoading(true);

    const response = await fetch(api.skins.all, {
      method: "POST",
      headers: new Headers({
        Authorization: session_key,
      }),
      body: JSON.stringify({
        skins,
      }),
    });

    props.setLoading(false);

    if (response.status === 200) {
      await showDialog("出售成功！");

      const descriptions = steamInventoryDescription.slice();

      // Mark the skins as deposited
      for (let i = 0; i < descriptions.length; ++i) {
        if (descriptions[i].selected) {
          descriptions[i].selected = false;
          descriptions[i].deposited = true;
        }
      }

      setSteamInventoryDescription(descriptions);
      setTotalPrice(0);
    } else {
      await showDialog("出售失敗，請稍後再試。");
    }
  };

  const handleReload = async (e) => {
    e.preventDefault();
    props.setLoading(true);
    await fetchSteamInventory(true);
  };

  if (steamInventoryDescription.length) {
    return (
      <Row>
        <Col xs="8">
          <Table data-bs-theme="dark">
            <thead>
              <tr>
                <th className="mid-lavender-background">
                  <Button size="sm" variant="secondary" onClick={handleReload}>
                    <IoReload style={{ fontSize: "20px" }} />
                  </Button>
                </th>
                <th className="mid-lavender-background">圖片</th>
                <th className="mid-lavender-background">物品</th>
                <th className="mid-lavender-background">價格</th>
              </tr>
            </thead>
            <tbody>
              {steamInventoryDescription.map((ele, id) => (
                <tr key={id}>
                  <td
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      background: ele.deposited ? "#eee" : "#fff",
                    }}
                    onClick={() => handleSelect(id)}
                    className="dark-lavender-background"
                  >
                    {ele.deposited && (
                      <div className="deposited-label">出售中</div>
                    )}
                    <Form.Check checked={ele.selected} readOnly />
                  </td>
                  <td
                    onClick={() => handleSelect(id)}
                    style={{ background: ele.deposited ? "#eee" : "#fff" }}
                    className="dark-lavender-background"
                  >
                    <img
                      src={imagePrefix + ele.icon_url}
                      style={{ width: "100px" }}
                      className="me-2"
                    />
                    <a
                      href={
                        ele.actions
                          ? getFullInspectLink(ele.actions[0].link, ele.assetid)
                          : ""
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink style={{ color: "gray" }} />
                    </a>
                    <br />
                    {ele.stickers?.map((ele, id) => (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltip({ name: ele.name })}
                      >
                        <img
                          key={id}
                          src={ele.link}
                          alt="sticker"
                          style={{ height: "1.5rem", width: "auto" }}
                        />
                      </OverlayTrigger>
                    ))}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      background: ele.deposited ? "#eee" : "#fff",
                    }}
                    onClick={() => handleSelect(id)}
                    className="dark-lavender-background"
                  >
                    {ele.market_name}
                    <br />
                    <span style={{ color: "gray", fontSize: "12px" }}>
                      {ele.float >= 0 && `磨損度: ${ele.float}`}
                      {ele.float < 0 && "磨損度: 無法取得"}
                    </span>
                    {ele.float >= 0 && <FloatVisualizer float={ele.float} />}
                  </td>
                  <td
                    style={{ background: ele.deposited ? "#eee" : "#fff" }}
                    className="dark-lavender-background"
                  >
                    <Form.Control
                      maxLength={17}
                      disabled={!ele.selected}
                      onChange={(e) => handlePriceChange(id, e.target.value)}
                      defaultValue={ele.price}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Card
            className="mt-2 dark-lavender-background"
            style={{ border: "none", color: "#ccc" }}
          >
            <Card.Text className="mt-4 mb-1">
              <span className="float-start ms-5">銷售所得</span>
              <span className="float-end me-5">{totalPrice.toFixed(2)}</span>
              <br />
              <span className="float-start ms-5 mt-2">
                -&nbsp;&nbsp;&nbsp;手續費(5%)
              </span>
              <span className="float-end me-5 mt-2">
                {(totalPrice * 0.05).toFixed(2)}
              </span>
            </Card.Text>
            <hr style={{ width: "80%" }} className="ms-auto me-auto mt-2" />
            <Card.Text className="mb-3">
              <span className="float-start ms-5">實際所得</span>
              <span className="float-end me-5">
                {(totalPrice * 0.95).toFixed(2)}
              </span>
            </Card.Text>
          </Card>
          <Button
            className="float-end me-5"
            onClick={handleSell}
            variant="outline-warning"
          >
            出售
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="mb-5">
        <h3 className="ms-auto me-auto mt-5" style={{ color: "#ccc" }}>
          您的 Steam 物品庫沒有任何物品。
        </h3>
      </Row>
    );
  }
};

export default MySteamInventory;
