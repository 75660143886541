import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import moment from "moment";
import Cover from "../../component/Cover";
import Loading from "../../component/Loading";
import { api } from "../../config/api";
import { getCookie } from "../../util/getCookie";

/**
 * 提領頁面。由於提領功能尚未確認作法，因此目前只有完成基本的 UI。
 */
const Withdraw = () => {
  // 是否全選
  const [selectAll, setSelectAll] = useState(false);

  // 載入中
  const [loading, setLoading] = useState(true);

  const [transactions, setTransactions] = useState([]);

  /**
   * 點擊訂單後，將訂單設為已選取或取消選取
   */
  const handleClickRow = (item) => {
    transactions.forEach((ele) => {
      if (ele.id === item.id && ele.withdraw_status_code === 0) {
        ele.selected = !ele.selected;
      }
    });
    setTransactions([...transactions]);
  };

  useEffect(() => {
    getOrders();
  }, []);

  /**
   * 取得使用者訂單資料
   */
  const getOrders = async () => {
    setLoading(true);

    const orders = await fetch(api.orders, {
      headers: {
        Authorization: getCookie("session_key"),
      },
    }).then((res) => res.json());

    console.log(orders);

    orders.forEach((ele, index) => {
      ele.selected = false;
      ele.id = index;
    });

    setTransactions(orders);
    setLoading(false);
  };

  return (
    <div className="basic-page no-main-navbar">
      {loading && <Loading />}
      <Cover title="提領" />
      <div
        className="text-start mb-5 mt-5"
        style={{ marginLeft: "10%", width: "80%", minWidth: "600px" }}
      >
        <Row>
          <Col xs={12} className="me-5">
            <Table data-bs-theme="dark">
              <thead>
                <tr>
                  <th style={{ textAlign: "center", verticalAlign: "center" }} className="mid-lavender-background">
                    <Button
                      size="sm"
                      variant="outline-warning"
                      onClick={() => {
                        setSelectAll(!selectAll);
                        transactions.forEach((item) => {
                          item.selected = !selectAll;
                        });
                        setTransactions([...transactions]);
                      }}
                    >
                      全選
                    </Button>
                  </th>
                  <th style={{ textAlign: "center" }} className="mid-lavender-background">訂單編號</th>
                  <th style={{ textAlign: "center" }} className="mid-lavender-background">總金額</th>
                  <th style={{ textAlign: "center" }} className="mid-lavender-background">物品</th>
                  <th style={{ textAlign: "center" }} className="mid-lavender-background">狀態</th>
                  <th style={{ textAlign: "center" }} className="mid-lavender-background">最後更新時間</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((ele, id) => (
                  <tr key={id} onClick={() => handleClickRow(ele)}>
                    <td
                      style={{
                        textAlign: "center",
                        background: ele.withdraw_status_code === 1 && "#eee",
                      }}
                      className="dark-lavender-background"
                    >
                      <Form.Check checked={ele.selected} readOnly />
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        background: ele.withdraw_status_code === 1 && "#eee",
                      }}
                      className="dark-lavender-background"
                    >
                      {ele.self_trade_no}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        background: ele.withdraw_status_code === 1 && "#eee",
                      }}
                      className="dark-lavender-background"
                    >
                      {ele.price}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        background: ele.withdraw_status_code === 1 && "#eee",
                      }}
                      className="dark-lavender-background"
                    >
                      {ele.content.map((item, id) => (
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px 0px 0px 10px",
                          }}
                          key={id}
                        >
                          {item.item_name}
                        </p>
                      ))}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        background: ele.withdraw_status_code === 1 && "#eee",
                      }}
                      className="dark-lavender-background"
                    >
                      {ele.withdraw_status_code === 0 ? "未提領" : "審核中"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        background: ele.withdraw_status_code === 1 && "#eee",
                      }}
                      className="dark-lavender-background"
                    >
                      {moment(ele.last_modified).format("YYYY-MM-DD HH:mm")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Withdraw;
