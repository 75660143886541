/**
   * 在當前分頁開啟 HTML 內容
   * @param {*} htmlContent 要顯示的 HTML 內容
   */
export const openHtml = (htmlContent) => {
  const blob = new Blob([htmlContent], { type: "text/html" });
  const url = URL.createObjectURL(blob);
  window.location.href = url;
};

/**
 * 將 base 加入到 HTML 字串中
 * @param {*} htmlString 原有的 HTML 字串
 * @param {*} baseHref 要加入的 base
 */
export const addBaseTagToHTML = (htmlString, baseHref) => {
  // Define the base tag
  const baseTag = `<base href="${baseHref}">`;

  // Use a regular expression to find the opening <head> tag
  const headOpeningTagRegex = /<head[^>]*>/i;

  // Check if the <head> tag is found
  const headOpeningTagMatch = htmlString.match(headOpeningTagRegex);

  if (headOpeningTagMatch) {
    // Insert the base tag after the <head> tag
    const modifiedHTML = htmlString.replace(
      headOpeningTagRegex,
      `${headOpeningTagMatch[0]}${baseTag}`
    );

    return modifiedHTML;
  } else {
    // If <head> tag is not found, simply append the base tag to the end
    return htmlString + baseTag;
  }
};
