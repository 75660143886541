/**
 * When the page is loading, this component will be displayed.
 */
const Loading = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
      }}
    >
      <div
        className="spinner-grow text-light mb-4"
        role="status"
        style={{ width: "5rem", height: "5rem" }}
      ></div>
      <p style={{ color: "#eee", fontSize: "20px" }}>TWSKINS is loading...</p>
    </div>
  );
};

export default Loading;
