import { Navbar, Container, Form } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectType } from "../features/type/type";
import {
  updateSearchKey,
  updateTrigger,
} from "../features/searchKey/searchKey";
import { updateType } from "../features/type/type";
import Dropdown from "./Dropdown/Dropdown";
import Loading from "./Loading";
import MidTierDropdown from "./Dropdown/MidTierDropdown";
import { api } from "../config/api";

const MainNavbar = () => {
  const [searchKey, setSearchKey] = useState("");
  const [types, setTypes] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const cachedTypes = useSelector(selectType);

  /**
   * Fetch the types of weapons.
   */
  const fetchTypes = async () => {
    setLoading(true);

    const typesResponse = await fetch(api.types).then((res) => res.json());

    setTypes(typesResponse);
    dispatch(updateType(typesResponse));
    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(cachedTypes).length === 0) {
      fetchTypes();
    } else {
      setTypes(cachedTypes);
    }
  }, []);

  /**
   * When clicking the search button, update the search key and trigger the search.
   */
  const handleSearch = () => {
    if (!searchKey) {
      return;
    }

    dispatch(updateSearchKey(searchKey));
    dispatch(updateTrigger());
  };

  return (
    <>
      {loading && <Loading />}
      {loading || (
        <>
          <Navbar
            style={{
              width: "100%",
              margin: "auto",
              position: "fixed",
              top: "50px",
              backgroundColor: "#eee",
              height: "50px",
              zIndex: "99",
            }}
            className="main-navbar"
          >
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                <Dropdown title="手槍" options={types.pistols} />
                <MidTierDropdown title="中階" options={types.mid_tier} />
                <Dropdown title="步槍" options={types.rifles} />
                <Dropdown title="近戰武器" options={types.knives} />
                <Dropdown title="手套" options={types.gloves} />
                <Dropdown title="幹員" options={types.agents} />
                <Form.Control
                  placeholder="找點什麼..."
                  className="search-input"
                  data-bs-theme="dark"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <div className="search-button">
                  <AiOutlineSearch
                    size={20}
                    color="#ccc"
                    onClick={handleSearch}
                  />
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      )}
    </>
  );
};

export default MainNavbar;
