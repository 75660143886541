import { api } from "../config/api";

// Login with Steam
export const steamLogin = async () => {
  // The redirect URL after login
  const fullUrl = window.location.href;

  const baseUrl = new URL(fullUrl).origin + new URL(fullUrl).pathname.replace(/\/[^\/]*$/, '/');

  // If the protocol is http, it is in development mode; otherwise, it is in production mode.
  const mode = baseUrl.includes("http:") ? "dev" : "prod";

  const { redirectUrl } = await fetch(
    `${api.login.login}?mode=${mode}&twskinsUrl=${baseUrl}`
  ).then((res) => res.json());

  window.location.replace(redirectUrl);
};
