import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../features/user/user";
import { selectUser } from "../../features/user/user";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { BankCodeToNameMap } from "../../util/bankList";
import { getCookie } from "../../util/getCookie";
import { updateLogin } from "../../features/login/login";
import { verifyNationalId } from "../../util/nationalId";
import {
  maskEmail,
  maskNationalId,
  maskPhoneNumber,
} from "../../util/maskInfo";
import Cover from "../../component/Cover";
import moment from "moment";
import Loading from "../../component/Loading";
import { api } from "../../config/api";
import showDialog from "../../component/Dialog/showDialog";

const Setting = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(0);
  const [phone, setPhone] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(0);
  const [tradeUrl, setTradeUrl] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [paymentInfo, setPaymentInfo] = useState([]);
  const [bankNames, setBankNames] = useState([]);
  const [bankCodes, setBankCodes] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState("中華郵政");
  const [selectedBankCode, setSelectedBankCode] = useState("700");
  const [bankAccount, setBankAccount] = useState("");
  const [bankAccountOwnerName, setBankAccountOwnerName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * 存摺封面照片
   */
  const [passbookPhoto, setPassbookPhoto] = useState(null);

  const [passbookPhotoType, setPassbookPhotoType] = useState("");

  /**
   * 身分證正面照片
   */
  const [nationIdCardPhoto, setNationIdCardPhoto] = useState(null);

  const [nationIdCardPhotoType, setNationIdCardPhotoType] = useState("");

  /**
   * 是否顯示新增銀行帳戶視窗
   */
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);

  /**
   * 是否顯示身分證驗證視窗
   */
  const [showNationalIdModal, setShowNationalIdModal] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    setBanksFromCodeMap();
    setEmail(user.email || "");
    setEmailVerified(user.email_verified);
    setPhone(user.phone || "");
    setPhoneVerified(user.phone_verified);
    setTradeUrl(user.steam_trade_url || "");
    setPaymentInfo(user.paymentInfo || []);
  }, []);

  /**
   * 從銀行代碼對照表取得銀行名稱
   */
  const setBanksFromCodeMap = () => {
    const banks = [];
    for (const code in BankCodeToNameMap) {
      banks.push(BankCodeToNameMap[code]);
    }
    setBankNames(banks);

    const codes = [];
    for (const code in BankCodeToNameMap) {
      codes.push(code);
    }
    setBankCodes(codes);
  };

  /**
   * 更新使用者的 email
   */
  const handleUpdateEmail = async () => {
    if (!email || (email === user.email && emailVerified)) return;

    setLoading(true);

    await fetch(api.userInfo.email.update, {
      method: "PUT",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ email }),
    });

    dispatch(updateUser({ ...user, email }));

    const response = await fetch(api.userInfo.email.verify, {
      method: "POST",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ email }),
    });

    if (response.status === 200) {
      await showDialog("驗證連結已寄出，請至信箱驗證");
    } else {
      await showDialog("寄送驗證連結失敗，請確認信箱是否正確");
    }

    setLoading(false);
  };

  const handleUpdatePhone = async () => {
    if (!phone || (phone === user.phone && phoneVerified)) return;

    setLoading(true);

    await fetch(api.userInfo.phone.update, {
      method: "PUT",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ phone }),
    });

    dispatch(updateUser({ ...user, phone }));

    const response = await fetch(api.userInfo.phone.verify, {
      method: "POST",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({ phone }),
    });

    if (response.status === 200) {
      await showDialog("驗證連結已寄出，請至手機驗證");
    } else {
      await showDialog("寄送驗證連結失敗，請確認手機號碼是否正確");
    }

    setLoading(false);
  };

  const handleUpdateTradeURL = async () => {
    setLoading(true);

    const response = await fetch(api.userInfo.tradeurl, {
      method: "PUT",
      headers: {
        Authorization: getCookie("session_key"),
      },
      body: JSON.stringify({
        tradeUrl,
      }),
    });

    if (response.status === 200) {
      await showDialog("儲存成功！");
    } else {
      await showDialog("儲存失敗，請稍後再試");
    }

    dispatch(updateUser({ ...user, steam_trade_url: tradeUrl }));
    setLoading(false);
  };

  /**
   * 驗證身份證字號並上傳身分證正面照
   */
  const handleVerifyNationalId = async () => {
    if (!firstName) {
      await showDialog("請輸入名字");
      return;
    }

    if (!lastName) {
      await showDialog("請輸入姓氏");
      return;
    }

    if (!nationalId) {
      await showDialog("請輸入身分證字號");
      return;
    }

    if (!verifyNationalId(nationalId)) {
      await showDialog("身分證字號格式錯誤");
      return;
    }

    if (nationIdCardPhoto === null) {
      await showDialog("請上傳身分證正面照");
      return;
    }

    setShowNationalIdModal(false);
    setLoading(true);

    try {
      const currentTime = moment().format("YYYY-MM-DD-HH-mm-ss");

      const filename = `${
        user.steam_id
      }-${nationalId}-${currentTime.toString()}.${
        nationIdCardPhotoType.split("/")[1]
      }`;

      // 上傳身分證正面照
      await fetch(api.images, {
        method: "POST",
        headers: {
          Authorization: getCookie("session_key"),
        },
        body: JSON.stringify({
          filename,
          photo: nationIdCardPhoto,
        }),
      });

      // 新增身分證資料
      const response = await fetch(api.userInfo.nationalId.update, {
        method: "POST",
        headers: {
          Authorization: getCookie("session_key"),
        },
        body: JSON.stringify({
          firstName,
          lastName,
          nationalId,
          filename,
        }),
      });

      setNationIdCardPhoto(null);
      setLoading(false);

      switch (response.status) {
        case 200:
          await showDialog("您的身分資料已進入審核程序");
          await refreshUser();
          break;
        case 400:
          await showDialog("您已新增過此身分資料");
          break;
        case 409:
          await showDialog("身分證字號已被使用");
          break;
        case 500:
          await showDialog("新增身分資料失敗");
          break;
      }
    } catch (error) {
      console.error(error);
      await showDialog("新增身分資料失敗");
      return;
    }
  };

  /**
   * 確認新增銀行帳戶
   */
  const handleCreateBankAccount = async () => {
    if (passbookPhoto === null) {
      await showDialog("請上傳存摺封面");
      return;
    }

    if (!bankAccount) {
      await showDialog("請輸入帳戶號碼");
      return;
    }

    if (!branchName) {
      await showDialog("請輸入分行名稱");
      return;
    }

    if (!bankAccountOwnerName) {
      await showDialog("請輸入戶名");
      return;
    }

    setShowBankAccountModal(false);
    setLoading(true);

    try {
      const currentTime = moment().format("YYYY-MM-DD-HH-mm-ss");

      const filename = `${
        user.steam_id
      }-${selectedBankCode}-${bankAccount}-${currentTime.toString()}.${
        passbookPhotoType.split("/")[1]
      }`;

      // 上傳存摺封面
      await fetch(
        "https://qjlgu04wg9.execute-api.ap-northeast-1.amazonaws.com/prod/image",
        {
          method: "POST",
          body: JSON.stringify({
            filename,
            photo: passbookPhoto,
          }),
        }
      );

      // 新增銀行帳戶
      const response = await fetch(
        "https://qjlgu04wg9.execute-api.ap-northeast-1.amazonaws.com/prod/user-info/payment",
        {
          method: "POST",
          body: JSON.stringify({
            steam_id: user.steam_id,
            bankName: selectedBankName,
            bankCode: selectedBankCode,
            branchName,
            bankAccount,
            bankAccountOwnerName,
            filename,
          }),
        }
      );

      setPassbookPhoto(null);
      setLoading(false);

      switch (response.status) {
        case 200:
          if (!user.national_id) {
            await showDialog(
              "您新增的帳戶已進入審核程序，但您尚未驗證身分證，請點擊驗證身分證後才能順利驗證帳戶"
            );
          } else {
            await showDialog("您新增的帳戶已進入審核程序");
          }
          await refreshUser();
          break;
        case 400:
          await showDialog("您已新增過此帳戶");
          break;
        case 500:
          await showDialog("新增銀行帳戶失敗");
          break;
      }
    } catch (error) {
      console.error(error);
      await showDialog("新增銀行帳戶失敗");
      return;
    }

    // 重設帳戶相關 state
    setBankAccount("");
    setBankAccountOwnerName("");
    setBranchName("");
    setPassbookPhoto(null);
    setSelectedBankCode("700");
    setSelectedBankName("中華郵政");
  };

  /**
   * 重新取得使用者資料
   */
  const refreshUser = async () => {
    try {
      const login = await fetch(api.login.verify, {
        headers: {
          Authorization: getCookie("session_key"),
        },
      }).then((res) => res.json());

      dispatch(updateLogin(true));
      dispatch(updateUser({ ...login.user }));
    } catch (error) {
      // No valid session key.
      dispatch(updateLogin(false));
      dispatch(updateUser({}));
    }
  };

  const handleSelectedBankNameChange = (e) => {
    setSelectedBankName(e.target.value);

    for (const code in BankCodeToNameMap) {
      if (BankCodeToNameMap[code] === e.target.value) {
        setSelectedBankCode(code);
        break;
      }
    }
  };

  const handleSelectedBankCodeChange = (e) => {
    setSelectedBankCode(e.target.value);
    setSelectedBankName(BankCodeToNameMap[e.target.value]);
  };

  const handleBankAccountChange = (e) => {
    setBankAccount(e.target.value);
  };

  const handleFileChange = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        // 檢查是否為圖檔
        if (!file.type.includes("image")) {
          await showDialog("請上傳圖片檔案");
          setPassbookPhoto(null);
          return;
        }

        setPassbookPhotoType(file.type);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          setPassbookPhoto(reader.result);
        };
      }
    } else {
      // 選擇檔案時按取消
      setPassbookPhoto(null);
    }
  };

  const handleNationalIdCardFileChange = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        // 檢查是否為圖檔
        if (!file.type.includes("image")) {
          await showDialog("請上傳圖片檔案");
          setNationIdCardPhoto(null);
          return;
        }

        setNationIdCardPhotoType(file.type);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          setNationIdCardPhoto(reader.result);
        };
      }
    } else {
      // 選擇檔案時按取消
      setNationIdCardPhoto(null);
    }
  };

  /**
   * 刪除銀行帳戶
   * @param {*} info_id 要刪除的帳戶 ID
   */
  const handleDeleteAccount = async (info_id) => {
    if (!window.confirm("確定要刪除此帳戶？")) {
      return;
    }

    setLoading(true);

    await fetch(
      "https://qjlgu04wg9.execute-api.ap-northeast-1.amazonaws.com/prod/user-info/payment",
      {
        method: "DELETE",
        body: JSON.stringify({
          steam_id: user.steam_id,
          info_id,
        }),
      }
    );

    await showDialog("刪除帳戶成功");

    await refreshUser();

    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      {/* <Modal
        show={showBankAccountModal}
        onHide={() => setShowBankAccountModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>新增銀行帳戶</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                銀行代碼<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  value={selectedBankCode}
                  onChange={handleSelectedBankCodeChange}
                >
                  {bankCodes.map((code, index) => (
                    <option key={index} value={code}>
                      {code}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                銀行名稱<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  value={selectedBankName}
                  onChange={handleSelectedBankNameChange}
                >
                  {bankNames.map((bank, index) => (
                    <option key={index} value={bank}>
                      {bank}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                分行名稱<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                戶名<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  value={bankAccountOwnerName}
                  onChange={(e) => setBankAccountOwnerName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                帳戶號碼<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  value={bankAccount}
                  onChange={handleBankAccountChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                存摺封面<span style={{ color: "red" }}>*</span>
                <br />
                <span style={{ fontSize: "12px", color: "#555" }}>
                  (僅接受圖檔)
                </span>
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowBankAccountModal(false)}
          >
            關閉
          </Button>
          <Button variant="primary" onClick={handleCreateBankAccount}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={showNationalIdModal}
        onHide={() => setShowNationalIdModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>身分驗證</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                姓氏<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                名字<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                身分證字號<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  value={nationalId}
                  onChange={(e) => setNationalId(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                身分證正面照<span style={{ color: "red" }}>*</span>
                <br />
                <span style={{ fontSize: "12px", color: "#555" }}>
                  (僅接受圖檔)
                </span>
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  accept="image/*"
                  type="file"
                  onChange={handleNationalIdCardFileChange}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowNationalIdModal(false)}
          >
            關閉
          </Button>
          <Button variant="primary" onClick={handleVerifyNationalId}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="basic-page no-main-navbar">
        <Cover title="帳戶設定" />
        <div
          className="text-start pb-3 mt-5"
          style={{
            marginLeft: "20%",
            width: "60%",
            minWidth: "600px",
            color: "#ccc",
          }}
        >
          <Form>
            <Form.Group as={Row} className="d-flex align-items-center mb-5">
              <Col xs={3}>
                <Form.Label
                  className="mt-auto mb-auto"
                  style={{ fontSize: "20px" }}
                >
                  Steam ID
                </Form.Label>
              </Col>
              <Col xs={5}>
                <Form.Text style={{ fontSize: "20px", color: "#ccc" }}>
                  {user.steam_id}
                </Form.Text>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="d-flex align-items-center mb-5"
              data-bs-theme="dark"
            >
              <Col xs={3}>
                <Form.Label
                  className="mt-auto mb-auto"
                  style={{ fontSize: "20px" }}
                >
                  交易連結
                </Form.Label>
              </Col>
              <Col xs={5} className="me-3">
                <Form.Control
                  style={{ fontSize: "20px", color: "#ccc" }}
                  value={tradeUrl}
                  onChange={(e) => setTradeUrl(e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleUpdateTradeURL}
                >
                  儲存
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="warning"
                  size="sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://steamcommunity.com/profiles/${user.steam_id}/tradeoffers/privacy`}
                >
                  尋找連結
                </Button>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="d-flex align-items-center mb-5"
              data-bs-theme="dark"
            >
              <Col xs={3}>
                <Form.Label
                  className="mt-auto mb-auto"
                  style={{ fontSize: "20px" }}
                >
                  {emailVerified ? "已認證信箱" : "未認證信箱"}
                  {emailVerified ? (
                    <FaCheck style={{ color: "green" }} className="ms-1" />
                  ) : (
                    <span style={{ color: "red" }} className="ms-1">
                      *
                    </span>
                  )}
                </Form.Label>
              </Col>
              <Col xs={5} className="me-3">
                {emailVerified ? (
                  <p style={{ margin: 0, fontSize: "20px" }}>
                    {maskEmail(email)}
                  </p>
                ) : (
                  <Form.Control
                    style={{ fontSize: "20px" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </Col>
              <Col>
                <Button
                  variant="success"
                  size="sm"
                  disabled={emailVerified}
                  onClick={handleUpdateEmail}
                >
                  驗證
                </Button>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="d-flex align-items-center mb-5"
              data-bs-theme="dark"
            >
              <Col xs={3}>
                <Form.Label
                  className="mt-auto mb-auto"
                  style={{ fontSize: "20px" }}
                >
                  {phoneVerified ? "已認證電話" : "未認證電話"}
                  {phoneVerified ? (
                    <FaCheck style={{ color: "green" }} className="ms-1" />
                  ) : (
                    <span style={{ color: "red" }} className="ms-1">
                      *
                    </span>
                  )}
                </Form.Label>
              </Col>
              <Col xs={5} className="me-3">
                {phoneVerified ? (
                  <p style={{ margin: 0, fontSize: "20px" }}>
                    {maskPhoneNumber(phone)}
                  </p>
                ) : (
                  <Form.Control
                    style={{ fontSize: "20px" }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                )}
              </Col>
              <Col>
                <Button
                  variant="success"
                  size="sm"
                  disabled={phoneVerified}
                  onClick={handleUpdatePhone}
                >
                  驗證
                </Button>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="d-flex align-items-center mb-5"
              data-bs-theme="dark"
            >
              <Col xs={3}>
                <Form.Label
                  className="mt-auto mb-auto"
                  style={{ fontSize: "20px" }}
                >
                  身分證字號
                  {user.national_id_status === 1 ? (
                    <FaCheck style={{ color: "green" }} className="ms-1" />
                  ) : (
                    <span style={{ color: "red" }} className="ms-1">
                      *
                    </span>
                  )}
                </Form.Label>
              </Col>
              <Col xs={5} className="me-3">
                <Form.Text style={{ fontSize: "20px", color: "#ccc" }}>
                  {user.national_id
                    ? maskNationalId(user.national_id)
                    : "尚無資料"}
                </Form.Text>
              </Col>
              <Col xs="auto">
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => setShowNationalIdModal(true)}
                >
                  驗證
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Setting;
