/**
 * 遮蔽部分帳號資訊，僅顯示末五碼
 * @param {*} account 銀行帳號
 */
export const maskBankAccount = (account) => {
  if (!account) {
    return '';
  }

  return '****' + account.slice(-5);
}

/**
 * 遮蔽部分身分證字號，僅顯示頭尾各一碼
 * @param {*} nationalId 身分證字號
 */
export const maskNationalId = (nationalId) => {
  if (!nationalId) {
    return '';
  }

  return nationalId.slice(0, 1) + '*********' + nationalId.slice(-1);
}

/**
 * 遮蔽部分 email，@ 之前的部分僅顯示頭尾字元
 * @param {*} email 
 */
export const maskEmail = (email) => {
  if (!email) {
    return '';
  }

  const [account, domain] = email.split('@');
  
  
  return account.slice(0, 1) + '****' + account.slice(-1) + '@' + domain;
}

/**
 * 遮蔽手機號碼中間三碼
 * @param {*} phoneNumber 手機號碼
 */
export const maskPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber.slice(0, 4) + '***' + phoneNumber.slice(-3);
}