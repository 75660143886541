import { useState } from "react";
import MySteamInventory from "./MySteamInventory";
import Loading from "../../../component/Loading";

const MySkin = () => {
  const [mySteamInventoryLoading, setMySteamInventoryLoading] = useState(true);

  return (
    <div
      style={{ width: "80%", minWidth: "600px" }}
      className="ms-auto me-auto mt-3 pb-5"
    >
      {mySteamInventoryLoading && <Loading />}
      <MySteamInventory setLoading={setMySteamInventoryLoading} />
    </div>
  );
};

export default MySkin;
