import { Button, Form } from "react-bootstrap";
import IdVerification from "./IdVerification";
import "../../style.css";
import { useState } from "react";
import Select from "../../../component/Select/Select";

const AdminDetail = () => {
  // 篩選的姓名
  const [name, setName] = useState("");

  // 篩選的身分證字號
  const [id, setId] = useState("");

  // 篩選審核的狀態
  const [verifiedStatusFilter, setVerifiedStatusFilter] = useState("all");

  // 時間排序
  const [timeOrderFilter, setTimeOrderFilter] = useState("new-to-old");

  // 觸發篩選事件
  const [trigger, setTrigger] = useState(false);

  /**
   * 根據篩選條件觸發 child component 的 filter function
   */
  const filter = () => {
    setTrigger(!trigger);
  };

  return (
    <>
      <div className="admin-filter mt-4 mb-4">
        <Form
          style={{
            display: "flex",
            flexDirection: "row",
            width: "90%",
            gap: "20px",
          }}
          data-bs-theme="dark"
        >
          <Form.Control
            type="text"
            placeholder="姓名"
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Control
            type="text"
            placeholder="身分證字號"
            onChange={(e) => setId(e.target.value)}
          />
          <Select
            title="審核狀態"
            options={{
              所有審核狀態: "all",
              待審核: "pending",
              已通過: "verified",
              未通過: "rejected",
            }}
            setValue={setVerifiedStatusFilter}
            canShowOptions={true}
          />
          <Select
            title="時間排序"
            options={{
              由新到舊: "new-to-old",
              由舊到新: "old-to-new",
            }}
            setValue={setTimeOrderFilter}
            canShowOptions={true}
          />
        </Form>
        <div style={{ width: "10%" }}>
          <Button onClick={filter}>篩選</Button>
        </div>
      </div>
      <IdVerification
        trigger={trigger}
        name={name}
        id={id}
        verifiedStatusFilter={verifiedStatusFilter}
        timeOrderFilter={timeOrderFilter}
      />
    </>
  );
};

export default AdminDetail;
