import MainNavbar from "../../component/MainNavbar";
import Cover from "../../component/Cover";
import CartDetails from "./component/CartDetails";

const ShoppingCart = () => {
  return (
    <div className="basic-page">
      <MainNavbar />
      <Cover title="購物車" />
      <CartDetails />
    </div>
  );
};

export default ShoppingCart;
